import { useEffect, useState } from 'react'
import { Grid } from "@mui/material"
import SimpleTextField from '../../../../components/Forms/FieldTypes/TextField';
import MaterialUiButton from '../../../../components/Buttons/MaterialUiButton/MaterialUiButton';
import { cancelScan, externalScanFromApp } from '../../../../utils/API/Assets/Assets';

export default function ReceiveForm(props) {
  const {
    asset,
    fieldComps,
    receiveFormState = {},
    selectedForm,
    setIsDialogOpen = () => { },
    setReceiveFormState = () => { },
    token,
    validateFormFields = () => { },
  } = props;
  const [formFields, setFormFields] = useState({});
  const {
    assetId,
    itemLabel,
    showFormFields,
  } = receiveFormState;
  const classificationMap = asset ? asset.classificationMap : null;

  function clearFormFields() {

    // Clear out the form field values
    setFormFields((prevState) => {
      let newState = { ...prevState };
      Object.keys(prevState).forEach((item) => {
        const specificField = newState[item];

        specificField.fieldValue = "";
      });

      return newState;
    });
  }

  function deleteAssetIdFromFormFields() {
    setFormFields((prevState) => {
      let newFormFields = { ...prevState };
      // need to parse through the formfiends and find one that has isAssetId and pop it off
      Object.keys(newFormFields).forEach((item) => {
        if (newFormFields[item].isAssetId) {
          delete newFormFields[item];
        }
      });

      return newFormFields;
    });
  }

  function cancelAction() {
    // Need to delete asset that was created from Todds endpoint
    cancelScan({ action: selectedForm, assetId, token }).then(
      (res) => {
        if (res.success) {
          // Need to set itemLabel back to default, showFormfields to false, and assetId back to empty string
          setReceiveFormState((prevState) => ({
            ...prevState,
            assetId: "",
            itemLabel: "",
            showFormFields: false,
          }));

          // Need to delete the assetId from the formFields
          deleteAssetIdFromFormFields();

          // Need to clear all the form values that might of been put in
          clearFormFields();
        } else {
          setReceiveFormState((prevState) => ({
            ...prevState,
            snackbarShow: true,
            snackbarSeverity: "error",
            snackbarText: res.error,
          }));
        }
      }
    );
  }

  function setValues(payload) {
    const {
      asset = {},
      error,
      success = false,
    } = payload;
    const { assetId } = asset;

    if (success) {
      setReceiveFormState((prevState) => ({
        ...prevState,
        receiveFormAssetId: assetId,
        showFormFields: true,
      }));
    } else {
      setReceiveFormState((prevState) => ({
        ...prevState,
        snackbarShow: true,
        snackbarText: error,
        snackbarSeverity: "error",
      }));
    }
  }

  // The useEffect grabs the selected forms fieldList and throws it into state
  useEffect(() => {
    if (selectedForm) {
      // This statement sets up the formFields
      // Does the form have fields?
      if (selectedForm?.fields) {
        const collectionOfFields = {};

        selectedForm.fields.forEach((field, index) => {
          const fieldCopy = { ...field };
          // We first check to see if the item is comign from a classification
          if (
            fieldCopy.fieldKey === "classificationMap" &&
            fieldCopy.topLevel &&
            classificationMap
          ) {
            const associatedClassification =
              asset.classificationMap[fieldCopy.topLevel];
            if (associatedClassification) {
              const fo = field.formOptions.find(
                (fo) => fo.label === associatedClassification
              );

              if (fo) {
                fieldCopy.fieldValue = fo.formOptionId;
              }
            }
          }

          collectionOfFields[index] = fieldCopy;
        });
        setFormFields(collectionOfFields);
      }
    }
  }, [asset?.classificationMap, classificationMap, selectedForm]);

  return (
    <Grid container sx={{ padding: '1rem' }}>
      {/* Item Label */}
      <Grid item xs={12}>
        <SimpleTextField
          label="Receiveing label"
          onChange={(e) => setReceiveFormState((prevState) => ({ ...prevState, itemLabel: e.target.value }))}
          readOnly={showFormFields}
          size="large"
          value={itemLabel}
        />
      </Grid>

      {/* Form Fields */}
      {showFormFields ? (
        <Grid item xs={12}>
          {fieldComps(formFields)}
        </Grid>
      ) : null}

      {/* Button Group */}
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        {/* Submit Button */}
        <MaterialUiButton
          color={showFormFields ? "cancel" : "submit"}
          label={showFormFields ? "Close" : "Submit"}
          onClick={() => {
            if (!showFormFields && itemLabel.length !== 0) {
              externalScanFromApp({
                assetMode: "Inventory",
                externalId: itemLabel,
                token,
              }).then((res) => {
                if (res.success) {
                  setValues(res);
                }
              })
            } else {
              // editAsset()
              // Close the Dialog
              setIsDialogOpen(false)

              // Check if the user missed any required fields in a form
              validateFormFields()
            }
          }}
        />

        {/* Cancel Button */}
        <MaterialUiButton
          color="delete"
          disabled={!showFormFields}
          label="Cancel"
          onClick={() => cancelAction()}
          sx={{ marginLeft: '1rem' }}
        />
      </Grid>
    </Grid>
  )
}