import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import Grid from "@mui/material/Grid";
import HoverIconButton from "../../ReusedComponents/HoverIconButton";
import { CircularProgress, Box, useTheme } from "@mui/material";
import { useState } from "react";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
// import CustomFooter from "./CustomFooter";

export default function MaterialUiDataGridPro(props) {
  const theme = useTheme()
  const { palette = {} } = theme;
  const { primary = {} } = palette;
  const classes = {
    container: {
      display: "flex",
      justifyContent: "space-between",
    },
    toolbarDataContainer: {
      display: "flex",
      color: "#6b7078",
    },
    toolbarData: {
      fontSize: "1.15em",
      marginLeft: "15px",
      marginRight: "15px",
      paddingTop: "5px",
    },
  }
  const {
    autoHeight = true,
    checkboxSelection = true,
    columns = [],
    customComponents = {},
    customFilters = null,
    customGridToolbar = null,
    disableColumnResize = false,
    disableColumnFilter = false,
    disableColumnSelector = false,
    disableExportOption = true,
    disableSelectionOnClick = true,
    disableToolbar = false,
    delayedLoad = false,
    exportItemType = "",
    exportFileName = "",
    getRowId = () => { },
    getTreeDataPath = () => { },
    handleConfirmationModal = () => { },
    handleExport = false,
    handleRowSelect = () => { },
    height = "80vh",
    hideFooter = false,
    hideFooterRowCount = false,
    loading = false,
    needsCustomToolbar = false,
    onFilterModelChange = () => { },
    onRowClick = () => { },
    onRowOrderChange = () => { },
    onSortModelChange = () => { },
    pageSize = 100,
    pagination = true,
    rowReordering = false,
    rows = [],
    selectionModel = undefined,
    showGridToolBar = false,
    state = {},
    sx = {

      // Makes the background of the table white
      backgroundColor: "white",
      borderColor: "primary.light",
      "& .MuiDataGrid-cell:hover": {
        color: "primary.main",
      },

      // Styles the headers
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
        backgroundColor: primary?.main,
        // backgroundColor: "red",
        color: "white",
        fontWeight: "bold",
      },

      // Styles the checkbox color
      "& .MuiDataGrid-columnHeader .MuiCheckbox-root": {
        color: "white",
      },

      // Styles the text inside of the header columns
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
      },

    },
    toolBarButtons = () => { },
    treeData = false,
  } = props;

  const [pgSize, setPgSize] = useState(pageSize);
  const [page, setPage] = useState(0);
  const apiRef = useGridApiRef();
  let components = {};

  // Appends the toolbar
  if (showGridToolBar) {
    // If the parent provides a custom toolbar, we will use that. Otherwise we use the default
    components.Toolbar =
      showGridToolBar && customGridToolbar
        ? customGridToolbar
        : showGridToolBar
          ? GridToolbar
          : null;
  }

  // Appends custom filters
  if (customFilters) {
    components.FilterPanel = customFilters;
  }

  // I need to figure out what was going on here. Should we use mine or his?
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={classes.container}>
        <Grid sx={classes.toolbarDataContainer}>
          <Grid sx={classes.toolbarData}>
            <GridToolbarColumnsButton
              sx={{ color: primary?.main }}
            />
          </Grid>
          <Grid sx={classes.toolbarData}>
            <GridToolbarFilterButton
              sx={{ color: primary?.main }}
            />
          </Grid>
          <Grid sx={classes.toolbarData}>
            <GridToolbarExport
              csvOptions={{ disableToolbarButton: disableExportOption, fileName: exportFileName }}
              printOptions={{ disableToolbarButton: true }}
              sx={{ color: primary?.main }}
            />
          </Grid>
          <Grid sx={classes.toolbarData}>
            <GridToolbarQuickFilter />
          </Grid>
          {/* <Grid sx={classes.toolbarData}>{selectedCount} selected</Grid> */}
        </Grid>
        <Grid sx={classes.toolbarDataContainer}>
          {toolBarButtons()}
        </Grid>
        {handleExport ?
          <Grid sx={classes.toolbarDataContainer}>
            <span
            >
              <HoverIconButton
                downloadDocType="Excel"
                exportItemType={exportItemType}
                handleClick={() => { handleExport(state, handleConfirmationModal) }}
                icon={<ContentPasteGoIcon/>}
                iconDirection="right"
                text="Export"
              />
            </span>
          </Grid>
          :
          <p>{"  "}</p>}

      </GridToolbarContainer>
    );
  }

  return (
    <Box height={height}>
      <DataGridPro
        apiRef={apiRef}
        autoHeight={autoHeight}
        //deprecated. now slotProps
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: disableExportOption, fileName: exportFileName },
            printOptions: { disableToolbarButton: true },
            quickFilterProps: { debounceMs: 500 },
            showQuickFilter: true,
          },
        }}
        checkboxSelection={checkboxSelection}
        columns={columns}
        //deprecated. now slots
        components={{
          Toolbar: disableToolbar ? null : needsCustomToolbar ? CustomToolbar : GridToolbar,
          ...customComponents,
        }}
        disableColumnResize={disableColumnResize}
        disableSelectionOnClick={disableSelectionOnClick}
        // following 3 props are used to disable toolbar icons when custom toolbar isnt in use
        disableColumnFilter={disableColumnFilter}
        disableColumnSelector={disableColumnSelector}
        disableDensitySelector
        experimentalFeatures={{ newEditingApi: true }}
        filterMode="client"
        getRowId={getRowId}
        getTreeDataPath={getTreeDataPath}
        hideFooterRowCount={hideFooterRowCount}
        hideFooter={hideFooter}
        keepNonExistentRowsSelected
        initialState={{
          sorting: {
            sortModel: [{
              field: 'timeOfLog', sort: 'desc'
            }]
          }
        }}
        loading={loading}
        localeText={{
          noRowsLabel: delayedLoad ? <CircularProgress /> : "No rows",
        }}
        onFilterModelChange={(newFilterModel) => {
          onFilterModelChange(newFilterModel);
        }}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPgSize(newPageSize)}
        onRowClick={(rowData) => onRowClick(rowData)}
        onRowOrderChange={(changeInfo) => onRowOrderChange(changeInfo)}
        onSelectionModelChange={(selectedRowIds) => {
          handleRowSelect(selectedRowIds);
        }}
        selectionModel={selectionModel}
        onSortModelChange={onSortModelChange}
        page={page}
        pageSize={pgSize}
        pagination={pagination}
        // paginationMode="client"
        // rowCount={rowCountState}
        // rowHeight={rowHeight}
        rowReordering={rowReordering}
        rows={rows}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        sx={sx}
        treeData={treeData}
      />
    </Box>
  );
}
