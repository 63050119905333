import { useEffect, useState } from "react";
import { Grid } from "@mui/material/";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AssetAnalysis from "../assetAnalysis/AssetAnalysis";
import AssetContent from "../createAsset/AssetContent";
import AssetHistory from "../assetHistory/AssetHistory";
import AssetStatus from "../assetStatus/AssetStatus";
import HorizontalTabs from "../../../components/Tabs/HorizontalTabs";
import NotificationModal from "../../../components/Modals/NotificationModal";

export default function Assets(props) {
  const [tab, setTab] = useState(0);
  const [alertModalState, setAlertModalState] = useState({
    alertModalColor: "success",
    modalShow: false,
    modalText: "",
  });
  const { alertModalColor, modalShow, modalText } = alertModalState;
  const classifications = useSelector(
    (state) => state.organization.classifications,
    isEqual
  );
  const customs = useSelector((state) => state.organization.customs, isEqual);
  const facilities = useSelector(
    (state) => state.organization.facilities,
    isEqual
  );
  const history = useHistory();

  // This useEffect looks at the query params and will manually control
  // the Horizontal Tabs below
  useEffect(() => {
    const currentTab = history.location.search;
    if (currentTab === "?tab=status") {
      setTab(0);
    } else if (currentTab === "?tab=history") {
      setTab(1);
    } else if (currentTab === "?tab=create") {
      setTab(2);
    }
    //add asset analysis tab
    else if (currentTab === "?tab=itemAnalysis") {
      setTab(3);
    }
    else if (currentTab === "?tab=eventAnalysis") {
      setTab(4);
    }
  }, [history.location.search]);

  const handleAlertModal = (content) => {
    const { modalText, alertModalColor = "success" } = content;

    // Open the Modal
    setAlertModalState((prevState) => ({
      ...prevState,
      modalText,
      modalShow: true,
      alertModalColor,
    }));

    // Close it
    setTimeout(
      () =>
        setAlertModalState((prevState) => ({
          ...prevState,
          modalShow: false,
          alertModalColor: "success",
        })),
      2000
    );
  };

  return (
    <Grid container>

      {/* Notification Modal */}
      <NotificationModal
        confirmationModalShow={modalShow}
        modalClose={() => {
          setAlertModalState(() => ({
            alertModalColor: "success",
            modalShow: false,
            modalText: "",
          }));
        }}
        confirmationText={modalText}
        color={alertModalColor}
      />
      <Grid item xs={12}>
        <HorizontalTabs
          appendQueryParams
          customValue={tab}
          fullWidth={true}
          noAppBarColor
          noElevation
        >
          {/* Asset Status */}
          <AssetStatus
            {...props}
            label="Status"
            onClick={() => {
              history.push({ search: "?tab=status" });
              setTab(0);
            }}
          />

          {/* Asset History */}
          <AssetHistory
            {...props}
            label="History"
            onClick={() => {
              history.push({ search: "?tab=history" });
              setTab(1);
            }}
          />

          {/* Create Asset */}
          <AssetContent
            {...props}
            classifications={classifications}
            customs={customs}
            facilities={facilities}
            handleAlertModal={handleAlertModal}
            label="Create"
            onClick={() => {
              history.push({ search: "?tab=create" });
              setTab(2);
            }}
          />

          {/* Asset Analysis */}
          <AssetAnalysis
            {...props}
            isAssetAnalysis={true}
            label="Asset Analysis"
            onClick={() => {
              history.push({ search: "?tab=itemAnalysis" });
              setTab(3);
            }}
            useSelector={useSelector}
          />

          {/* Event Analysis */}
          <AssetAnalysis
            {...props}
            isAssetAnalysis={false}
            label="Event Analysis"
            onClick={() => {
              history.push({ search: "?tab=eventAnalysis" });
              setTab(4);
            }}
            useSelector={useSelector}
          />

        </HorizontalTabs>
      </Grid>
    </Grid>
  );
}
