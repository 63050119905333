import { timeLongToyyyyMMddHHmmss } from "../../globalStore/utils/helpers";

export const getAssetProps = async (props, assetId) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}assets/${assetId}?includeConsoleExtras=true`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const submitEditAsset = async (
  props,
  assetData,
  editAsset,
  selectedClassifications,
  note
) => {
  const customMap = {};
  const reducedProps = {};
  const standAloneProps = {}; //for properties that are not custom, classification, or part of the propertiesMap
  console.log("assetData: ", assetData)

  Object.keys(editAsset).forEach((item) => {
    // Here we divvy up whether or not items are customs
    if (editAsset[item].isCustom) {
      // Verify there is a value in the custom before appending it to the object
      if (editAsset[item].value.length > 0) {
        customMap[item] = editAsset[item].value;
      }
    } 
    else if (editAsset[item].isStandAlone) {
      if (item === "timeNeeded") {
        const currFormat = editAsset[item].value;
        //change to this format: "yyyyMMddHHmm" 
        const finalFormat = timeLongToyyyyMMddHHmmss(currFormat);
        standAloneProps[item] = finalFormat;
      }
      //if datatype is date, convert to milliseconds... or whatever correct format ends up being from backend
      else if (editAsset[item].dataType === "Date") {
        standAloneProps[item] = editAsset[item].value.getTime();
      }
      else {
      standAloneProps[item] = editAsset[item].value;
      }
    }
    else {
      reducedProps[item] = editAsset[item].value;
    }
  });

  const body = {
    classificationMap: {},
    category: reducedProps.category || assetData.category,
    // TODO: put timeNeeded value in here. Convert from Date format to milliseconds
    // timeNeededLong: editAsset.timeNeeded ? editAsset.timeNeeded.getTime() : assetData.timeNeededLong,
    // timeNeeded: editAsset.timeNeeded || assetData.timeNeededLong,
    classificationSet: [],
    customMap,
    propertiesMap: {
      ...assetData.propertiesMap,
      ...reducedProps,
      note,
    },
    ...standAloneProps,
  };

  // Handle Classifications
  // Object.keys(selectedClassifications).forEach((item) => {
  //   body.classificationSet.push(selectedClassifications[item].value);
  // });

  Object.keys(selectedClassifications).forEach((classification) => {
    const selectedClassification = selectedClassifications[classification];
    const { label = "", parentName = "" } = selectedClassification;
    body.classificationMap[parentName] = label;
  });

  console.log("body: ", body);
  // return
  const results = await fetch(`${props.apiUrl}assets/${assetData.assetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export const submitUpdateEvent = async (props, updateEvent) => {
  const {
    action,
    apiUrl,
    assetData,
    facilities,
    formFields,
    isReceiveForm,
    receiveFormAssetId,
    staticFormFields,
    token,
    userId,
  } = props;
  const {
    location,
    event,
    note,
    zone = null,
    binLocation = null,
  } = updateEvent;
  let latitude = null;
  let longitude = null;
  let assetId = "";
  const { formId = null } = action;

  if (
    location &&
    facilities[location.value] &&
    facilities[location.value].location
  ) {
    latitude = facilities[location.value]?.location?.latitude || null;
    longitude = facilities[location.value]?.location?.longitude || null;
  }
  let payload = {
    latitude,
    longitude,
    facility: facilities[location?.value] || null,
    action: '',
    appUserId: userId,
    binLocation: binLocation || null,
    zone:
      zone?.zoneId || binLocation
        ? {
          zoneId: zone?.zoneId || null,
          binLocation: binLocation || null,
        }
        : null,
    propertiesMap: {
      formId,
      formData: [],
      note: note,
    },

  };

  // Here we set the form action.
  payload.action = event;

  // Here we set the assetId. If the form contains another assetId. Then it will be changed in the fields down below
  assetId = assetData.assetId;

  function appendFieldsToBody(fields = {}) {
    // Here we form the submit body - We traverse the formFields object and append the values
    Object.keys(fields).forEach((field) => {
      const specificField = fields[field];
      const { fieldKey, fieldType, fieldValue } = specificField;

      if (fieldKey === "assetId") {
        assetId = fieldValue;
      } else if (fieldType === "scannedAssets") {
        // This is a temporary fix. Need to come back and deal with the assetIdTextField to store this in an array.
        // Or create a new component.
        payload.propertiesMap.formData.push({
          fieldType,
          fieldKey,
          fieldValues: [fieldValue],
        });
      } else {
        payload.propertiesMap.formData.push(specificField);
      }
    });
  }

  if (staticFormFields && !isReceiveForm) {
    appendFieldsToBody(staticFormFields);
  }

  appendFieldsToBody(formFields);

  if (isReceiveForm) {
    payload.propertiesMap.formData.push({ fieldType: 'associate', fieldValues: [receiveFormAssetId] })
  }

  const results = await fetch(`${apiUrl}assets/${assetId}/action`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update event, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (
  props,
  assetSnapshotSettings
) => {
  const { apiUrl, token, userId } = props;

  // Removing filters that do not need to be saved on the users asset snapshot settings
  [
    "binLocation",
    "endDate",
    "events",
    "locals",
    "locations",
    "start",
    "startDate",
    "users",
    "zones",
  ].forEach((item) => {
    if (assetSnapshotSettings && assetSnapshotSettings[item]) {
      delete assetSnapshotSettings[item];
    }
  });

  const payload = {
    propertiesMap: {
      assetSnapshotSettings: assetSnapshotSettings,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const deleteAssembly = async (props, assemblyId) => {
  const { apiUrl, assetData, token } = props;
  const { assetId } = assetData;
  const result = await fetch(`${apiUrl}assets/removeAssembly`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify({ assemblyId: assemblyId, assetId: assetId }),
  })
    .then((response) => response.json())
    .then((json) => {
      console.log("json: ", json)
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to delete assembly, please contact system administrator.",
      };
    });

  return result;
};

// TODO - Elastic - Need to test this function to verify it works once telemetry data comes in
// Commenting out both functions for now. We might need to reference these as we further mature
// devices and telemetry
// export const getTelemetry = async (
//   props,
//   deviceId,
//   filters,
//   page
// ) => {
//   const { apiUrl, organizationId } = props;
//   const csmToken = process.env.REACT_APP_CSM_TOKEN;
//   let data = {};
//   let {
//     devices = [],
//     // start = 0,
//     limit,
//     startDate,
//     endDate,
//     types,
//     radios,
//     nodes,
//   } = filters;

//   let elasticQuery = {
//     csmToken,
//     organizationId,
//     elasticSearchQuery: {
//       bool: {
//         must_not: [],
//         must: [
//           {
//             term: {
//               asset_id: deviceId,
//             },
//           },
//         ],
//       },
//     },
//     limit: limit ? limit : 25000,
//     page,
//     sort: `time_of_report DESC`,
//   };

//   // Devices
//   if (devices && devices.length) {
//     let deviceArray = devices.map((e) => {
//       return e.value;
//     });
//     elasticQuery.elasticSearchQuery.bool.must.push({
//       terms: {
//         asset_id: deviceArray,
//       },
//     });
//   }

//   // Report Type
//   if (types && types.length) {
//     let typesArray = types.map((e) => {
//       return e.value;
//     });
//     elasticQuery.elasticSearchQuery.bool.must.push({
//       terms: {
//         report_type: typesArray,
//       },
//     });
//   }

//   // Radios
//   if (radios && radios.length) {
//     let radiosArray = radios.map((e) => {
//       return e.value;
//     });
//     elasticQuery.elasticSearchQuery.bool.must.push({
//       terms: {
//         radio: radiosArray,
//       },
//     });
//   }

//   // Nodes
//   if (nodes && nodes.length) {
//     let nodesArray = nodes.map((e) => {
//       return e.value;
//     });
//     elasticQuery.elasticSearchQuery.bool.must.push({
//       terms: {
//         node: nodesArray,
//       },
//     });
//   }

//   // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
//   if (moment(startDate).isValid() || moment(endDate).isValid()) {
//     elasticQuery.elasticSearchQuery.bool.must.push({
//       range: {
//         time_of_log: {
//           gte: moment(startDate).isValid()
//             ? moment(startDate).startOf("day").valueOf()
//             : null,
//           lte: moment(endDate).isValid()
//             ? moment(endDate).endOf("day").valueOf()
//             : null,
//         },
//       },
//     });
//   }

//   const lastReportBody = {
//     csmToken,
//     elasticSearchQuery: {
//       bool: {
//         must_not: [],
//         must: [
//           {
//             term: {
//               asset_id: deviceId,
//             },
//           },
//           {
//             term: {
//               report_type: "R",
//             },
//           },
//         ],
//       },
//     },
//     limit: 1,
//     sort: `time_of_report DESC`,
//   };

//   const lastMovementBody = {
//     csmToken,
//     elasticSearchQuery: {
//       bool: {
//         must_not: [],
//         must: [
//           {
//             term: {
//               asset_id: deviceId,
//             },
//           },
//           {
//             term: {
//               report_type: "M",
//             },
//           },
//         ],
//       },
//     },
//     limit: 1,
//     sort: `time_of_report DESC`,
//   };

//   const telemetryData = await fetch(`${apiUrl}telemetry/csm/console/search`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(elasticQuery),
//   })
//     .then((response) => response.json())
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       console.log(err);
//       return {
//         error: "Failed to fetch Device, please contact system administrator.",
//       };
//     });

//   const lastReport = await fetch(`${apiUrl}telemetry/csm/console/search`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(lastReportBody),
//   })
//     .then((response) => response.json())
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       console.log(err);
//       return {
//         error: "Failed to fetch Device, please contact system administrator.",
//       };
//     });

//   const lastMovement = await fetch(`${apiUrl}telemetry/csm/console/search`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(lastMovementBody),
//   })
//     .then((response) => response.json())
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => {
//       console.log(err);
//       return {
//         error: "Failed to fetch Device, please contact system administrator.",
//       };
//     });

//   data = { ...telemetryData, lastReport, lastMovement };

//   return data;
// };

// export const retrieveRadioIds = async (props) => {
//   const { apiUrl } = props;
//   const csmToken = process.env.REACT_APP_CSM_TOKEN;
//   const body = {
//     csmToken,
//   };
//   const results = await fetch(`${apiUrl}radios/csm/console`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(body),
//   })
//     .then((response) => response.json())
//     .then((response) => {
//       const nodes = {};
//       const radios = {};
//       response.radios.forEach((item) => {
//         nodes[item.node] = item.node;
//         radios[item.radio] = item.radio;
//       });
//       return { nodes, radios };
//     });

//   return results;
// };
export const retrieveAssetChildren = async (props, assetId) => {
  const { organizationId, apiUrl, token, zones, facilities } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [
          {
            regexp: {
              "event": {
                "value": "Scan.*LogisticsPro",
              }
            }
          },
        ],
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            term: {
              parent_id: assetId,
            },
          },
        ],
      },
    },
    limit: 1000,
    sort: `time_of_log DESC`,
  };

  const results = await fetch(`${apiUrl}assets/search?includeConsoleExtras=true`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((res) => res.json())
    .then((res) => {
      return (
        res.assets?.map((asset) => {
          const zone =
            asset.zone && asset.zone.zoneId && zones[asset.zone.zoneId]
              ? zones[asset.zone.zoneId].name
              : null;
          const binLocation =
            asset.zone && asset.zone.binLocation
              ? asset.zone.binLocation
              : null;
          return {
            assetTag: asset.tag,
            ancestorList: asset.ancestorList || [],
            ancestors: asset.ancestors || [],
            binLocation,
            facility: facilities[asset.facilityId]
              ? facilities[asset.facilityId].name
              : "",
            id: asset.assetId,
            lastEvent: asset.lastEvent,
            parentId: asset.parentId,
            quantityNeeded: asset.quantity
              ? asset.quantity.quantityNeeded
              : null,
            quantityPicked: asset.quantity
              ? asset.quantity.quantityPicked
              : null,
            zone,
          };
        }) || []
      );
    });
  return results;
};

export const resetDisplayColumnOptions = async (props) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: {
        displayColumnOptions: null,
        defaultColumnOrder: [],
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const getTreeData = async (facilityId, itemNumber, organizationId, apiUrl, token) => {

  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              organization_id: organizationId,
            }
          },
          {
            term: {
              facility_id: facilityId
            }
          },
          {
            nested: {
              path: "custom_data",
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        "custom_data.key.keyword": "Item Number"
                      }
                    },
                    {
                      match: {
                        "custom_data.value.keyword": itemNumber
                      }
                    }

                  ]
                }
              }
            }
          }

        ]
      }
    },
    facilityId: facilityId,
    treeType: "zoneCountByFacility"
  }

  const response = await fetch(`${apiUrl}/assets/createTree`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(elasticQuery),
  });
  const data = await response.json();
  return data
}

// Image Functions. Used in AssetSnapshot.js -> ImagesDisplayNSave.js

export const editAssetImage = (props) => {
  const { apiUrl, token, assetId, imageId, name } = props;
  const formData = new FormData();
  const jsonData = { name };
  formData.append("jsondata", JSON.stringify(jsonData));

  const response = fetch(`${apiUrl}assets/${assetId}/images/${imageId}`, {
    method: "PUT",
    headers: {
      "auth-token": token,
    },
    body: formData,
  }).then((res) => {
    return res.json();
  })
    .then((json) => {
      return json;
    });
  return response;
};

export const deleteAssetImage = (props) => {
  const { apiUrl, token, assetId, imageId } = props;

  const response = fetch(`${apiUrl}assets/${assetId}/images/${imageId}`, {
    method: "DELETE",
    headers: {
      "auth-token": token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    });
  return response;
};

export const uploadAssetImage = (props) => {
  const { apiUrl, token, assetId, image, imageType, name } = props;
  const jsonData = { name };
  const formData = new FormData();
  const newBlob = new Blob([image]);

  //get image type but only part after slash so "image/png" becomes "png"
  const imageTypeShort = imageType.split("/")[1];

  //if imageTypeShort is "jpeg" or "jpg", dont prepend it to "imagedata"
  if (imageTypeShort === "jpeg" || imageTypeShort === "jpg") {
    formData.append("imagedata", newBlob);
  } else {
    formData.append(`${imageTypeShort}imagedata`, newBlob);
  }

  formData.append("jsondata", JSON.stringify(jsonData));

  const response = fetch(`${apiUrl}assets/${assetId}/images`, {
    method: "POST",
    headers: {
      "auth-token": token,
    },
    body: formData,
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json;
    });
  return response;
};