import { isEmpty } from "lodash";

export default function buildDefaultColumns(props) {
  // isInventory is a boolean we can adjust the values returned
  const {
    appUserType,
    classifications,
    customs,
    isDevice,
    isInventory = false,
    isHistory = false,
    isSnapshot = false,
  } = props;

  const showPCA = ![
    "Asset-Operations",
    "Asset",
    "Warehouse-Operations",
  ].includes(appUserType);

  // Snapshot columns are straight forward.
  if (isSnapshot) {
    const snapshotColumns = [
      // timeOfLog
      {
        name: "timeOfLog",
        displayPerspective: "all",
        description: "Time of Log",
        displayName: "Date/Time",
        displayOrder: 1,
        displaySort: "asc",
        displayVisible: true,
        id: "timeOfLog",
      },
      // assetTag
      {
        name: "assetTag",
        displayPerspective: "all",
        description: `${isInventory ? "Inventory" : "Asset"} Tag`,
        displayName: `${isInventory ? "Inventory" : "Asset"} Tag`,
        displayOrder: 2,
        displaySort: "asc",
        displayVisible: true,
        id: "assetTag",
      },
      // assemblyId
      {
        name: "assemblyId",
        displayPerspective: "all",
        description: "assemblyId",
        displayName: "Ticket Id",
        displayOrder: 3,
        displaySort: "asc",
        displayVisible: true,
        id: "assemblyId",
      },
      // event
      {
        name: "event",
        displayPerspective: "all",
        description: "event",
        displayName: "Event",
        displayOrder: 4,
        displaySort: "asc",
        displayVisible: true,
        id: "event",
      },
      // user
      {
        name: "user",
        displayPerspective: "all",
        description: "user",
        displayName: "User",
        displayOrder: 5,
        displaySort: "asc",
        displayVisible: true,
        id: "user",
      },
      // facility
      {
        name: "facility",
        displayPerspective: "all",
        description: "facility",
        displayName: "Facility Name",
        displayOrder: 6,
        displaySort: "asc",
        displayVisible: true,
        id: "facility",
      },
      // zone
      {
        name: "zone",
        displayPerspective: "all",
        description: "zone",
        displayName: "Zone",
        displayOrder: 7,
        displaySort: "asc",
        displayVisible: true,
        id: "zone",
      },
      // binLocation
      {
        name: "binLocation",
        displayPerspective: "all",
        description: "binLocation",
        displayName: "Bin Location",
        displayOrder: 8,
        displaySort: "asc",
        displayVisible: true,
        id: "binLocation",
      },
      // state
      {
        name: "state",
        displayPerspective: "all",
        description: "state",
        displayName: "State",
        displayOrder: 9,
        displaySort: "asc",
        displayVisible: true,
        id: "state",
      },
      // latLong
      {
        name: "latLong",
        displayPerspective: "all",
        description: "latLong",
        displayName: "Latitude, Longitude",
        displayOrder: 10,
        displaySort: "asc",
        displayVisible: true,
        id: "latLong",
      },
      // quantityCurrent
      {
        name: "quantityCurrent",
        displayPerspective: "all",
        description: "quantityCurrent",
        displayName: "Qty",
        displayOrder: 11,
        displaySort: "asc",
        displayVisible: true,
        id: "quantityCurrent",
      },
    ].filter((col) => {
      if (isInventory && col.id === "state") {
        return false;
      }

      // since this file is used to show table for assets and inventory assets' snapshot, filter out assembly id column when on asset snapshot table
      else if (!isInventory && col.id === "assemblyName") {
        return false;
      }
      // CBE - 12/21/2023 - bringing latLong back for inventory snapshot
      // else if (isInventory && col.id === "latLong") {
      //   return false;
      // }
      else if (!showPCA) {
        return col.id !== "flagged";
      } else {
        return true;
      }
    });

    return snapshotColumns;
  }

  // Devices columns are straight forward.
  if (isDevice) {
    const deviceColumns = [
      {
        name: "timeCreatedLong",
        displayPerspective: "all",
        description: "timeCreatedLong",
        displayName: "Created Date",
        displayOrder: 1,
        displaySort: "asc",
        displayVisible: true,
        id: "timeCreatedLong",
      },
      {
        name: "tag",
        displayPerspective: "all",
        description: "tag",
        displayName: "Tracker Identifier",
        displayOrder: 2,
        displaySort: "asc",
        displayVisible: true,
        id: "tag",
      },
      {
        name: "timeModifiedLong",
        displayPerspective: "all",
        description: "timeModifiedLong",
        displayName: "Last Modified Date",
        displayOrder: 3,
        displaySort: "asc",
        displayVisible: true,
        id: "timeModifiedLong",
      },
      {
        name: "lastEvent",
        displayPerspective: "all",
        description: "lastEvent",
        displayName: "Status",
        displayOrder: 4,
        displaySort: "asc",
        displayVisible: true,
        id: "lastEvent",
      },
      {
        name: "platform",
        displayPerspective: "all",
        description: "platform",
        displayName: "Manufacturer",
        displayOrder: 5,
        displaySort: "asc",
        displayVisible: true,
        id: "platform",
      },
      {
        name: "assetType",
        displayPerspective: "all",
        description: "assetType",
        displayName: "Tracker Type",
        displayOrder: 6,
        displaySort: "asc",
        displayVisible: true,
        id: "assetType",
      },
      {
        name: "batteryLife",
        displayPerspective: "all",
        description: "batteryLife",
        displayName: "Battery Life (%)",
        displayOrder: 7,
        displaySort: "asc",
        displayVisible: true,
        id: "batteryLife",
      },
      {
        name: "speed",
        displayPerspective: "all",
        description: "speed",
        displayName: "Speed (m/h)",
        displayOrder: 8,
        displaySort: "asc",
        displayVisible: true,
        id: "speed",
      },
      {
        name: "altitude",
        displayPerspective: "all",
        description: "altitude",
        displayName: "Altitude (m above sea level)",
        displayOrder: 9,
        displaySort: "asc",
        displayVisible: true,
        id: "altitude",
      },
      {
        name: "heading",
        displayPerspective: "all",
        description: "heading",
        displayName: "Heading (°)",
        displayOrder: 10,
        displaySort: "asc",
        displayVisible: true,
        id: "heading",
      },
      {
        name: "temp",
        displayPerspective: "all",
        description: "temp",
        displayName: "Temperature (°C)",
        displayOrder: 11,
        displaySort: "asc",
        displayVisible: true,
        id: "temp",
      },
      {
        name: "humidity",
        displayPerspective: "all",
        description: "humidity",
        displayName: "Humidity (%)",
        displayOrder: 12,
        displaySort: "asc",
        displayVisible: true,
        id: "humidity",
      }
    ];
    return deviceColumns;
  }
  // This number will iterate up. Will be used for customData elements and Classifications
  let displayOrderCount = 0;

  // This function is here to set the displayOrderCount to the correct number. I could chain a ternary
  // but for readablity sake imma make this dumb

  // Asset Status
  if (!isInventory && !isHistory) {
    displayOrderCount = 14;
  }
  // Inventory Status
  else if (isInventory && !isHistory) {
    displayOrderCount = 12;
  }
  // Asset History
  else if (!isInventory && isHistory) {
    displayOrderCount = 10;
  }
  // Inventory History
  else if (isInventory && isHistory) {
    displayOrderCount = 12;
  }

  // Columns for assetStatus and inventoryStatus pages
  let columns = [
    // timeOfLog
    {
      name: "timeOfLog",
      displayPerspective: "all",
      description: "Time of Log",
      displayName: "Date/Time",
      displayOrder: 1,
      displaySort: "asc",
      displayVisible: true,
      id: "timeOfLog",
    },
    // tag
    {
      name: "tag",
      displayPerspective: "all",
      description: `${isInventory ? "Inventory" : "Asset"} Tag`,
      displayName: `${isInventory ? "Inventory" : "Asset"} Tag`,
      displayOrder: 2,
      displaySort: "asc",
      displayVisible: true,
      id: "tag",
    },
    // category
    {
      name: "category",
      displayPerspective: "all",
      description: `${isInventory ? "Inventory" : "Asset"} category`,
      displayName: `${isInventory ? "Inventory" : "Asset"} category`,
      displayOrder: 3,
      displaySort: "asc",
      displayVisible: true,
      id: "category",
    },
    // description
    {
      name: "description",
      displayPerspective: "all",
      description: "description",
      displayName: "Description",
      displayOrder: 4,
      displaySort: "asc",
      displayVisible: true,
      id: "description",
    },
    // lastEvent
    {
      name: "lastEvent",
      displayPerspective: "all",
      description: "lastEvent",
      displayName: "Last Event",
      displayOrder: 5,
      displaySort: "asc",
      displayVisible: true,
      id: "lastEvent",
    },
    // user
    {
      name: "user",
      displayPerspective: "all",
      description: "user",
      displayName: "User",
      displayOrder: 6,
      displaySort: "asc",
      displayVisible: true,
      id: "user",
    },
    // facility
    {
      name: "facility",
      displayPerspective: "all",
      description: "facility",
      displayName: "Facility",
      displayOrder: 7,
      displaySort: "asc",
      displayVisible: true,
      id: "facility",
    },
    // zone
    {
      name: "zone",
      displayPerspective: "all",
      description: "zone",
      displayName: "Zone",
      displayOrder: 8,
      displaySort: "asc",
      displayVisible: true,
      id: "zone",
    },
    // binLocation
    {
      name: "binLocation",
      displayPerspective: "all",
      description: "binLocation",
      displayName: "Bin Location",
      displayOrder: 9,
      displaySort: "asc",
      displayVisible: true,
      id: "binLocation",
    },
  ];

  // for Asset Status
  if (!isInventory && !isHistory) {
    //when setting these columns, the displayOrder of the column with the highest displayOrder should be used as the starting point for the new columns
    //so it should match the displayOrderCount in the if statements above
    const assetColumns = [
      // state
      {
        name: "state",
        displayPerspective: "all",
        description: "state",
        displayName: "State",
        displayOrder: 10,
        displaySort: "asc",
        displayVisible: true,
        id: "state",
      },
      // latLong
      {
        name: "latLong",
        displayPerspective: "all",
        description: "latLong",
        displayName: "Latitude, Longitude",
        displayOrder: 11,
        displaySort: "asc",
        displayVisible: true,
        id: "latLong",
      },
      // parentId
      {
        name: "parentId",
        displayPerspective: "all",
        description: "parentId",
        displayName: "Parent Asset Tag",
        displayOrder: 12,
        displaySort: "asc",
        displayVisible: true,
        id: "parentId",
      },
      // timeCreatedUser
      {
        name: "timeCreatedUser",
        displayPerspective: "all",
        description: "timeCreatedUser",
        displayName: "Create Date",
        displayOrder: 13,
        displaySort: "asc",
        displayVisible: true,
        id: "timeCreatedUser",
      },
      // timeNeeded
      {
        name: "timeNeeded",
        displayPerspective: "all",
        description: "timeNeeded",
        displayName: "Need Date",
        displayOrder: 14,
        displaySort: "asc",
        displayVisible: true,
        id: "timeNeeded",
      },
    ];

    columns = [...columns, ...assetColumns];
  }
  // for Inventory Status
  else if (isInventory && !isHistory) {
    const inventoryColumns = [
      // parentId
      {
        name: "parentId",
        displayPerspective: "all",
        description: "parentId",
        displayName: "Parent Asset Tag",
        displayOrder: 10,
        displaySort: "asc",
        displayVisible: true,
        id: "parentId",
      },
      // quantityCurrent
      {
        name: "quantityCurrent",
        displayPerspective: "all",
        description: "quantityCurrent",
        displayName: "Qty",
        displayOrder: 11,
        displaySort: "asc",
        displayVisible: true,
        id: "quantityCurrent",
      },
      // latLong
      {
        name: "latLong",
        displayPerspective: "all",
        description: "latLong",
        displayName: "Latitude, Longitude",
        displayOrder: 12,
        displaySort: "asc",
        displayVisible: true,
        id: "latLong",
      },
    ];
    columns = [...columns, ...inventoryColumns];
  }

  // Columns for assetHistory and inventoryHistory pages
  let historyColumns = [
    // parentId
    {
      name: "parentId",
      displayPerspective: "all",
      description: "parentId",
      displayName: "Parent Asset Tag",
      displayOrder: 10,
      displaySort: "asc",
      displayVisible: true,
      id: "parentId",
    },
    // timeOfLog
    {
      name: "timeOfLog",
      displayPerspective: "all",
      description: "Time of Log",
      displayName: "Date/Time",
      displayOrder: 1,
      displaySort: "asc",
      displayVisible: true,
      id: "timeOfLog",
    },
    // assetTag
    {
      name: "assetTag",
      displayPerspective: "all",
      description: `${isInventory ? "Inventory" : "Asset"} Tag`,
      displayName: `${isInventory ? "Inventory" : "Asset"} Tag`,
      displayOrder: 2,
      displaySort: "asc",
      displayVisible: true,
      id: "assetTag",
    },
    // category
    {
      name: "category",
      displayPerspective: "all",
      description: `${isInventory ? "Inventory" : "Asset"} category`,
      displayName: `${isInventory ? "Inventory" : "Asset"} category`,
      displayOrder: isHistory && isInventory ? 4 : 3,
      displaySort: "asc",
      displayVisible: true,
      id: "category",
    },
    // event
    {
      name: "event",
      displayPerspective: "all",
      description: "event",
      displayName: "Event",
      displayOrder: isHistory && isInventory ? 5 : 4,
      displaySort: "asc",
      displayVisible: true,
      id: "event",
    },
    // user
    {
      name: "user",
      displayPerspective: "all",
      description: "user",
      displayName: "User",
      displayOrder: isHistory && isInventory ? 6 : 5,
      displaySort: "asc",
      displayVisible: true,
      id: "user",
    },
    // facility
    {
      name: "facility",
      displayPerspective: "all",
      description: "facility",
      displayName: "Facility Name",
      displayOrder: isHistory && isInventory ? 7 : 6,
      displaySort: "asc",
      displayVisible: true,
      id: "facility",
    },
    // zone
    {
      name: "zone",
      displayPerspective: "all",
      description: "zone",
      displayName: "Zone",
      displayOrder: isHistory && isInventory ? 8 : 7,
      displaySort: "asc",
      displayVisible: true,
      id: "zone",
    },
    // binLocation
    {
      name: "binLocation",
      displayPerspective: "all",
      description: "binLocation",
      displayName: "Bin Location",
      displayOrder: isHistory && isInventory ? 9 : 8,
      displaySort: "asc",
      displayVisible: true,
      id: "binLocation",
    },
    // state
    {
      name: "state",
      displayPerspective: "all",
      description: "state",
      displayName: "State",
      displayOrder: isHistory && isInventory ? 10 : 9,
      displaySort: "asc",
      displayVisible: true,
      id: "state",
    },
  ];
  // For Inventory History
  if (isHistory && isInventory) {
    // Append the assemblyId field to the 2nd index of the array
    historyColumns.splice(2, 0, {
      name: "assemblyId",
      displayPerspective: "all",
      description: "assemblyId",
      displayName: "Ticket Id",
      displayOrder: 3,
      displaySort: "asc",
      displayVisible: true,
      id: "assemblyId",
    });

    // Push on the quantityCurrent
    historyColumns.push({
      name: "quantityCurrent",
      displayPerspective: "all",
      description: "quantityCurrent",
      displayName: "Quantity Qty",
      displayOrder: 11,
      displaySort: "asc",
      displayVisible: true,
      id: "quantityCurrent",
    });

    historyColumns.push({
      name: "latLong",
      displayPerspective: "all",
      description: "latLong",
      displayName: "Latitude, Longitude",
      displayOrder: 12,
      displaySort: "asc",
      displayVisible: true,
      id: "latLong",
    });
  }
  // For Asset History
  else if (isHistory && !isInventory) {
    historyColumns.push({
      name: "latLong",
      displayPerspective: "all",
      description: "latLong",
      displayName: "Latitude, Longitude",
      displayOrder: 10,
      displaySort: "asc",
      displayVisible: true,
      id: "latLong",
    });
  }

  // Append classifictions
  if (!isEmpty(classifications)) {
    let classificationColumns = [];

    Object.keys(classifications).map((classKey) => {
      // assetMode can be either Inventory or Undefined
      const { assetMode, name } = classifications[classKey];

      function pushItem() {
        // Iterate the displayOrderCount by one
        displayOrderCount++;

        classificationColumns.push({
          name: name,
          displayPerspective: "all",
          description: name,
          displayName: name,
          displayOrder: displayOrderCount,
          displaySort: "asc",
          displayVisible: true,
          id: name,
        });
      }

      // This is dumb. Check inventoryStatus/Table for this weird logic
      if (isInventory && name === "Comments") {
        pushItem();
      } else if (
        isInventory &&
        assetMode === "Inventory" &&
        !name.includes("Comments")
      ) {
        pushItem();
      } else if (!isInventory && assetMode !== "Inventory") {
        pushItem();
      }

      return null;
    });

    if (isHistory) {
      historyColumns = [...historyColumns, ...classificationColumns];
    } else {
      columns = [...columns, ...classificationColumns];
    }
  }

  // Append Customs
  if (!isEmpty(customs)) {
    const customColumns = customs.map((item) => {
      const { name } = item;

      // Iterate the displayOrderCount by one
      displayOrderCount++;

      return {
        name: name,
        displayPerspective: "all",
        description: name,
        displayName: name,
        displayOrder: displayOrderCount,
        displaySort: "asc",
        displayVisible: true,
        id: name,
      };
    });

    if (isHistory) {
      historyColumns = [...historyColumns, ...customColumns];
      //remove duplicates
      historyColumns = historyColumns.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.name === item.name)
      );
    } else {
      columns = [...columns, ...customColumns];
      //remove duplicates
      columns = columns.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.name === item.name)
      );
    }
  }

  if (isHistory) {
    return historyColumns;
  } else {
    return columns;
  }
}

export function buildExistingColumns(props) {
  const { classifications, columns, customs, tableName } = props;
  //customs and classifications only need to be added on status and history tables
  //update B.E. 12/15/23: customs and classifications need to be added to snapshot tables as well. At least Inventory for now
  //if it's a snapshot table, sort and return columns
  // if (tableName === "InventorySnapshot" || tableName === "AssetSnapshot") {
  if (tableName === "AssetSnapshot" || tableName === "Device") {
    //sort and return columns
    return [...columns].sort((a, b) => a.displayOrder - b.displayOrder);
  }
  //sort the existing columns by displayOrder
  const sortedExistingColumns = [...columns].sort(
    (a, b) => a.displayOrder - b.displayOrder
  );
  //get display order of the last column. Thats our starting point for the new columns
  let lastDisplayOrderVal =
    sortedExistingColumns[sortedExistingColumns.length - 1]?.displayOrder;

  //iterate over customs and add them to the existing columns, but only if they don't already exist
  //when adding them to the existing columns, add them to the end of the list, with the displayOrder being the lastDisplayOrderVal + 1
  // only add inventory customs to inventory tables and vice versa

  //append customs to the end of the existing columns
  if (!isEmpty(customs)) {
    customs.forEach((custom) => {
      const { name } = custom;
      //check to make sure the custom column doesn't already exist
      if (!sortedExistingColumns.some((column) => column.name === name)) {
        if (
          custom.assetMode === "Inventory" &&
          (tableName === "InventoryHistory" ||
            tableName === "InventoryStatus" ||
            tableName === "InventorySnapshot")
        ) {
          sortedExistingColumns.push({
            name: name,
            displayPerspective: "all",
            description: name,
            displayName: name,
            displayOrder: lastDisplayOrderVal + 1,
            displaySort: "asc",
            displayVisible: true,
            id: name,
          });
          lastDisplayOrderVal++;
        } else if (
          custom.assetMode === "Asset" &&
          (tableName === "AssetHistory" || tableName === "AssetStatus")
        ) {
          sortedExistingColumns.push({
            name: name,
            displayPerspective: "all",
            description: name,
            displayName: name,
            displayOrder: lastDisplayOrderVal + 1,
            displaySort: "asc",
            displayVisible: true,
            id: name,
          });
          lastDisplayOrderVal++;
        }
      }
    });
  }
  //iterate over classifications and add them to the existing columns, but only if they don't already exist
  //when adding them to the existing columns, add them to the end of the list, with the displayOrder being the lastDisplayOrderVal + 1
  if (!isEmpty(classifications)) {
    Object.keys(classifications).forEach((classKey) => {
      const { assetMode, name } = classifications[classKey];
      //check to make sure the classification column doesn't already exist
      if (!sortedExistingColumns.some((column) => column.name === name)) {
        if (
          assetMode === "Inventory" &&
          (tableName === "InventoryHistory" ||
            tableName === "InventoryStatus" ||
            tableName === "InventorySnapshot")
        ) {
          sortedExistingColumns.push({
            name: name,
            displayPerspective: "all",
            description: name,
            displayName: name,
            displayOrder: lastDisplayOrderVal + 1,
            displaySort: "asc",
            displayVisible: true,
            id: name,
          });
          lastDisplayOrderVal++;
        } else if (
          assetMode === "Asset" &&
          (tableName === "AssetHistory" || tableName === "AssetStatus")
        ) {
          sortedExistingColumns.push({
            name: name,
            displayPerspective: "all",
            description: name,
            displayName: name,
            displayOrder: lastDisplayOrderVal + 1,
            displaySort: "asc",
            displayVisible: true,
            id: name,
          });
          lastDisplayOrderVal++;
        }
      }
    });
  }

  // Here we do a catch for columns that were added after the fact. Think there's a better backend way of doing this.
  // But for now, this logic lives here. Fun!
  if (tableName === "InventoryHistory") {
    // We need to check if the parentId columns i in the sortedExistingColumns
    const parentIdColumn = sortedExistingColumns.find((column) => column.name === "parentId");

    // If so, we do nothing. If not we insert the parentId column at the 2nd index
    if (!parentIdColumn) {
      sortedExistingColumns.splice(2, 0, {
        name: "parentId",
        displayPerspective: "all",
        description: "parentId",
        displayName: "Parent Asset Tag",
        displayOrder: 3,
        displaySort: "asc",
        displayVisible: true,
        id: "parentId",
      });
    }
  }


  return sortedExistingColumns;
}
