import { Grid } from "@mui/material";
import Dashboard from "../dashboard/dashboard";

export default function SplashPage(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Dashboard {...props} />
      </Grid>
    </Grid>
  );
}