import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MaterialUiDataGridPremium from "../../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function Devices(props) {
  const history = useHistory();

  const deviceAssetsMap = useSelector(
    (state) => state.devices.deviceAssetsMap
  ) || {};
  const devices = useSelector((state) => state.organization.devices);

  const zoneDevices = Object.keys(deviceAssetsMap)
    .filter((deviceId) => {
      return (
        deviceAssetsMap[deviceId][0].zone?.zoneId === props.selectedZone.zoneId
      );
    })
    .map((deviceId) => devices[deviceId]);

  const { selectedZone = {}, facility = {} } = props;
  const { name } = selectedZone;

  useEffect(() => { }, [selectedZone]);

  const columns = [
    // Created Date
    {
      field: "timeCreatedLong",
      headerName: "Created Date",
      id: "timeCreatedLong",
      renderCell: (data) => data?.row?.timeCreatedLong || ""
    },
    // Tracker Identifier
    {
      field: "tag",
      headerName: "Tracker Identifier",
      id: "tag",
      renderCell: (data) => data?.row?.tag || ''
    },
    // Last Modified Date
    {
      field: "timeModifiedLong",
      headerName: "Last Modified Date",
      id: "timeModifiedLong",
      renderCell: (data) => data?.row?.timeModifiedLong || ''
    },
    // Availability
    {
      field: "status",
      headerName: "Availability",
      id: "status",
      renderCell: (data) => data?.row?.device?.status || "unassigned",
    },
    // Associated Item
    {
      field: 'assignedTo',
      headerName: "Associated Item",
      id: "assignedTo",
      renderCell: (data) => {
        const { row = {} } = data;
        return (
          <div
            className="pointer"
            onClick={() => {
              const {
                assetId = "",
                batchId = "",
                assetType = "",
              } = deviceAssetsMap[row?.assetId][0];
              if (assetId || batchId) {
                const url = batchId
                  ? `/batches/${batchId}/history/${assetType}`
                  : `/assetSnapshot/${assetId}`;
                history.push(url);
              }
            }}
          >
            {deviceAssetsMap[row?.assetId]
              ? deviceAssetsMap[row?.assetId].map((ass) => ass.tag)[0]
              : ""}
          </div>
        );
      },
    },
    // Last Event
    {
      field: "lastEvent",
      headerName: "Last Event",
      id: "lastEvent",
      renderCell: (data) => data?.row?.lastEvent || ''
    },
    // Device Type
    {
      field: "assetType",
      headerName: "Device Type",
      id: "assetType",
      renderCell: (data) => data?.row?.assetType || ''
    },
    // Battery Life
    {
      field: "batteryLife",
      headerName: "Battery Life",
      id: "batteryLife",
      renderCell: (data) => {
        const { row = {} } = data;
        return (
          <div onClick={() => console.log(data)}>
            {row.propertiesMap?.battery ? `${row.propertiesMap.battery}%` : ""}
          </div>
        );
      },
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {" "}
        <Typography component="h1" variant="h5">
          {name}
          {facility?.name ? ` | ${facility.name}` : ""} Devices
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialUiDataGridPremium
          columns={columns}
          getRowId={(row) => row.assetId}
          rows={zoneDevices}
        />
      </Grid>
    </Grid>
  );
}
