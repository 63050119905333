import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import Typography from "@mui/material/Typography";

export default function ConfirmationDialog(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { primary = {} } = palette;

  const classes = {
    button: {
      margin: "1rem 0",
    },
    content: {
      textAlign: "center",
    },
    title: {
      backgroundColor: primary?.main,
      color: "white",
      textAlign: "center",
    },
  }

  const { cqrLayoutName, modalShow, onChange, onSubmit, open, setState } =
    props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        setState((prevState) => ({
          ...prevState,
          cqrLayoutName: { value: "", error: false },
        }));
        modalShow();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={classes.title} id="alert-dialog-title">
        Save CQR Layout
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid sx={classes.content} item xs={12}>
            <Typography variant="body1" gutterBottom>
              Define CQR Layout Name
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SimpleTextField
              error={cqrLayoutName.error}
              helperText={cqrLayoutName.error ? "Required Field" : ""}
              id="outlined-basic"
              onChange={(event) => {
                onChange(event.target.value);
              }}
              required={true}
              value={cqrLayoutName.value}
              variant="outlined"
            />
          </Grid>
          <Grid sx={classes.button} item xs={12}>
            <MaterialUiButton
              color="submit"
              fullWidth={true}
              label="Submit"
              onClick={() => {
                if (cqrLayoutName.value.length === 0) {
                  setState((prevState) => ({
                    ...prevState,
                    cqrLayoutName: { value: "", error: true },
                  }));
                } else {
                  onSubmit();
                }
              }}
              variant="contained"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
