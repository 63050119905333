import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Dropzone } from "../../../components/Dropzone/Dropzone";
import { uploadPDFImage } from "./api";
import Grid from "@mui/material/Grid";

export default function FacilityImageUpload(props) {

  const classes = {
    dropZone: {
      padding: "0 5rem",
      textAlign:'center'
    },
  }

  const { handleSnackbar, setState } = props;
  const [loading, setLoading] = useState(false);
  return (
    <Grid sx={classes.dropZone} container>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Dropzone
            // accept=".pdf"
            accept="application/pdf, image/png"
            acceptText="Drag and drop or click to select file. Accepted file types are PDF and PNG."
            callback={(acceptedFiles, accepted) => {
              setLoading(true);
              accepted(true);
              uploadPDFImage({...props, file:acceptedFiles, handleSnackbar, setState})
            }}
            clearFile={() => {
              console.log("clear files");
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
