import { lazy, Suspense, useState } from "react";
import { init } from "../globalStore/slices/organization/organizationSlice";
import { initAssetHistory } from "../globalStore/slices/assetHistory/thunks/init";
import { initAssets } from "../globalStore/slices/assets/thunks/init";
import { initDevices } from "../globalStore/slices/devices/devicesSlice";
import { initInventoryHistory } from "../globalStore/slices/inventoryHistory/thunks/init";
import { onLogin } from "../globalStore/slices/user/userSlice";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import NoAuthLayout from "../components/Layout/NoAuthLayout";
import TwoFactorAuthPage from "../pages/2fa/TwoFactorAuthPage";

const UserInvitation = lazy(() => import("../pages/login/UserInvitation"));
const ForgotPassword = lazy(() => import("../pages/login/ForgotPassword"));
const Login = lazy(() => import("../pages/login/Login"));
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export default function NoAuthContainer(props) {
  const { setAuth } = props;
  const [confirmationModal, setConfirmationModal] = useState({
    error: false,
    show: false,
    text: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();

  function retrieveUserAndToken(user, token, url) {
    // retrieveUserAndToken is a callback function that gets fired from the login view upon login success
    // after login success, we dispatch the 'onLogin' action that initializers the user's data store
    dispatch(
      onLogin({
        user,
        token,
      })
    ).then(() => {
      // dispatching initializing functions, init() initializes the organization's data store, then we fetch some of the initial data for some of our views
      dispatch(init()).then(() => {
        // these initializors fetch initial data based on user preferences for the devices, asset status, and asset history views.
        dispatch(initDevices());
        dispatch(initAssets());
        dispatch(initAssetHistory());
        dispatch(initInventoryHistory());
        setAuth((auth) => {
          return {
            ...auth,
            isAuthed: true,
          };
        });

        history.push(url);
      });
    });
  }

  function logUserIn({ user = {}, token }) {
    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search.substring(1));
    const batchId = params.get("batchId");
    const assetType = params.get("assetType");
    const {
      appUserId,
      email,
      firstName,
      lastName,
      organizationId,
      userRoles = [],
    } = user;
    const consoleRoles =
      userRoles?.find((role) => role.name === "console")?.roles || [];
    const mobileRoles =
      userRoles?.find((role) => role.name === "mobile")?.roles || [];

      //using both "Mobile Only" and "No Console Access" for potential existing users with the old role names.
      //  Mobile Only is the new role name for No Console Access
    if (consoleRoles.some((role) => role.name === "Mobile Only")) {
      setConfirmationModal({
        error: true,
        show: true,
        text: "Your administrator has not configured your account to have access to LXConnect. If you believe this is a mistake, please contact your organization's administrative user.",
      });
      return;
    }
    
    if (consoleRoles.some((role) => role.name === " No Console Access")) {
      setConfirmationModal({
        error: true,
        show: true,
        text: "Your administrator has not configured your account to have access to LXConnect. If you believe this is a mistake, please contact your organization's administrative user.",
      });
      return;
    }

    if (mobileRoles.some((role) => role.name === "No Mobile Access")) {
      setConfirmationModal({
        error: true,
        show: true,
        text: "Your administrator has not configured your account to have access to LXConnect. If you believe this is a mistake, please contact your organization's administrative user.",
      });
      return;
    }

    // Sets values in local storage to survive a hard refresh
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("organizationId", organizationId);
    localStorage.setItem("token", token);
    localStorage.setItem("userEmail", email);
    localStorage.setItem("userId", appUserId);

    if (batchId !== null && assetType !== null) {
      retrieveUserAndToken(
        user,
        token,
        `/batches/${batchId}/history/${assetType}`
      );
    } else {
      retrieveUserAndToken(user, token, `/`);
    }
  }

  const NoAuthRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => <Component {...props} apiUrl={apiUrl} />}
      />
    );
  };

  return (
    <NoAuthLayout>
      <Suspense fallback={<div />}>
        <Switch>
          {/* 2FA Auth Page */}
          <NoAuthRoute
            exact
            path="/2faAuth*"
            // component={TwoFactorAuthPage}
            component={({ ...rest }) => (
              <TwoFactorAuthPage {...props} {...rest} logUserIn={logUserIn} />
            )}
            logUserIn={logUserIn}
          />

          {/* Initial set up of a accont */}
          <NoAuthRoute
            exact
            path="/appUsers/:token/invitation"
            component={UserInvitation}
          />

          {/* Forgot Password */}
          <NoAuthRoute
            exact
            path="/appUsers/:token/forgotPassword"
            component={ForgotPassword}
          />

          {/* Password Reset */}
          <NoAuthRoute
            exact
            path="/appUsers/:token/passwordReset"
            component={ForgotPassword}
          />

          {/* Another forgot password? Wonder which one we need */}
          <NoAuthRoute
            exact
            path="/users/:token/forgotPassword"
            component={ForgotPassword}
          />

          {/* Login Page */}
          <NoAuthRoute
            path={["/login", "/"]}
            component={({ ...rest }) => (
              <Login
                {...props}
                {...rest}
                apiUrl={apiUrl}
                confirmationModal={confirmationModal}
                logUserIn={logUserIn}
                retrieveUserAndToken={retrieveUserAndToken}
                setConfirmationModal={setConfirmationModal}
              />
            )}
          />
        </Switch>
      </Suspense>
    </NoAuthLayout>
  );
}
