import { useHistory } from "react-router-dom";
import buildColumns from "./columns";
import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function Table(props) {
  const {
    apiUrl,
    appUserType,
    classifications,
    customs,
    facilities,
    handleSave,
    setState,
    state,
    token,
    toolBarButtons,
    userId,
    useSelector,
    zones,
  } = props;

  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const history = useHistory();
  const specificConfig = displayConfigs.find(
    (element) => element.type === "InventoryHistory"
  );
  const columnOrder = specificConfig?.columns || null;
  const timeZone = props.timeZone?.value || null;
  const { historyTableColumns } = buildColumns({
    apiUrl,
    appUserType,
    classifications,
    columnOrder,
    customs,
    displayColumnOptions: state.filters?.displayColumnOptions || {},
    facilities,
    handleSave,
    history,
    parentAssetIdTagMap: state.parentAssetIdTagMap,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  function handleRowSelect(selectedRowIds) {
    let updatedSelectedAssets = {};

    const assetsList = state.histories || [];
    for (let asset of assetsList) {
      for (let rowId of selectedRowIds) {
        if (asset.assetHistoryId === rowId) {
          updatedSelectedAssets = {
            ...updatedSelectedAssets,
            [asset.assetHistoryId]: asset,
          };
        }
      }
    }

    setState((s) => {
      return {
        ...s,
        selectedAssets: updatedSelectedAssets,
      };
    });
  }

  return (
    <MaterialUiDataGridPremium
      autoHeight={false}
      checkboxSelectedIds={Object.keys(state.selectedAssets)}
      checkboxSelection={true}
      columns={historyTableColumns}
      delayedLoad={state.histories?.length === 0 ? false : true}
      disableAggregation={false}
      exportFileName={"Inventory-History"}
      getRowId={(row) => row.assetHistoryId}
      handleRowSelect={handleRowSelect}
      loading={false}
      needsCustomToolbar={true}
      rowCount={state.histories?.length}
      rows={state.histories || []}
      showGridToolBar={true}
      toolBarButtons={toolBarButtons}
    />
  );
}
