import { Box, Grid, Typography } from "@mui/material";
import { getFixedNodesByZone } from "./api";
import { searchRadios } from "../../../utils/API/Radios/Radios";
import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditNode from "./EditNode/EditNode";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import RadioTable from "./RadioTable/RadioTable";
import ViewNodeInformation from "./ViewNodeInformation/ViewNodeInformation";


export default function ConfigureZone(props) {
  const { handleAlertModal, setView, view } = props;
  const { zoneInfo = {} } = view;
  const { name = "", zoneId = "" } = zoneInfo;
  const [state, setState] = useState({
    echos: [],
    radios: [],
    selectedMountPointId: null,
    selectedNode: {},
    selectedEchoId: null,
    selectedEcho: {},
    selectedRadio: {},
  });


  useEffect(() => {
    getFixedNodesByZone(props, zoneId).then((res) => {
      const { fixedNodes = [] } = res;
      setState((prevState) => ({
        ...prevState,
        echos: fixedNodes,
      }));

      searchRadios(props, zoneId).then((res) => {
        const { radios = [] } = res;
        setState((prevState) => ({
          ...prevState,
          radios,
        }));
      });
    });
  }, [props, zoneId]);

  const [dialog, setDialog] = useState({
    dialogTitle: "",
    dialogType: "",
    dialogShow: false,
  });
  const { dialogTitle, dialogShow, dialogType } = dialog;

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
    });

  const switchDialog = (item) => {
    switch (item) {
      case "Edit":
        return (
          <EditNode
            {...props}
            dialog={dialog}
            dialogClose={dialogClose}
            handleAlertModal={handleAlertModal}
            setState={setState}
            showTitle={false}
            state={state}
          />
        );
      case "View":
        return <ViewNodeInformation {...props} state={state} />;
      default:
        return;
    }
  };

  return (
    <Grid container>
      <Box display="flex" ml={1} width="100%">
        <ModalDialog
          content={switchDialog(dialogType)}
          handleClose={dialogClose}
          hasFormFields={true}
          maxWidth="md"
          open={dialogShow}
          title={dialogTitle}
        />
        <Grid item xs={12}>
          <Box mt={2} mb={2} textAlign="left">
            <Typography component="h1" variant="h4">
              {name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={2} textAlign="right">
            <HoverIconButton
              cypressId="facilities-configure-zone-btn-back"
              icon={<ArrowBackIcon/>}
              iconDirection="left"
              handleClick={() =>
                setView((prevState) => ({
                  ...prevState,
                  viewConfigureZone: false,
                  zoneInfo: {},
                }))
              }
              text="Back to Zones"
            />
          </Box>
        </Grid>
      </Box>
      <Grid container>
        {/* <EchoTable
          {...props}
          setDialog={setDialog}
          setState={setState}
          state={state}
          echos={state.echos}
          zoneId={zoneId}
        /> */}

        <RadioTable
          {...props}
          setDialog={setDialog}
          setState={setState}
          state={state}
          radios={state.radios}
          zoneId={zoneId}
        />
      </Grid>
    </Grid>
  );
}
