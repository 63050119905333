import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { isEqual } from "lodash";
import { searchHistories } from "../../../utils/API/AssetHistories/AssetHistories";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import buildColumns from "../inventoryHistory/Table/columns";
import MaterialUiDataGridPremium from "../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

function Movement(props) {
  const {
    apiUrl,
    appUserType,
    handleSave,
    organizationId,
    setState,
    token,
    userId,
  } = props;
  const [movementEvents, setMovementEvents] = useState([]);
  const { classifications, customs, facilities, zones } = useSelector(
    (state) => state.organization,
    isEqual
  );
  const history = useHistory();
  const timeZone = props.timeZone?.value || null;
  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const specificConfig = displayConfigs.find(
    (element) => element.type === "MovementHistory"
  );
  const columnOrder = specificConfig?.columns || null;
  const { historyTableColumns } = buildColumns({
    apiUrl,
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities,
    handleSave,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  useEffect(() => {
    async function fetchData() {
      const histories = await searchHistories(
        {
          apiUrl,
          organizationId,
          timeZone,
          token,
        },
        {
          filters: {
            events: ["Zone Arrival", "Zone Departure"],
            limit: 1000,
            sorted: [{ id: "timeOfLog", desc: true }],
          },
        }
      );
      setMovementEvents(histories.assetHistories);
    }

    fetchData();
  }, [apiUrl, organizationId, timeZone, token]);

  return (
    <Grid
      container
      style={{
        marginTop: "1rem",
        width: "84vw",
        margin: "0 auto",
      }}
    >
      <Grid item xs={12}>
        <MaterialUiDataGridPremium
          autoHeight={false}
          checkboxSelection={false}
          columns={historyTableColumns}
          disableAggregation={false}
          exportFileName={"Inventory-Movement"}
          getRowId={(row) => row.assetHistoryId}
          rows={movementEvents}
        />
      </Grid>
    </Grid>
  );
}

export default Movement;
