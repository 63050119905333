import { Box, Card, CardContent, Grid } from "@mui/material";
import { isEqual } from "lodash";
import { isValidDateObject } from "../../../globalStore/utils/helpers";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { searchHistories } from "../../../utils/API/AssetHistories/AssetHistories";
import { useState } from "react";
import { useSelector } from "react-redux";
import BinSelector from "../../../components/Selects/BinSelector/BinSelector";
import CustomFilters from "../../../components/Selects/CustomsSelector/CustomFilters";
import EventAndUserFilters from "./EventAndUserFilters";
import LXDatePicker from "../../../components/Forms/FieldTypes/DatePicker";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import ClassificationFilters from "../../../components/Selects/ClassificationsSelector/ClassificationFilters";

export default function InventoryAnalyzeFilters(props) {
  const { apiUrl, isItemAnalysis, organizationId, setState, state, token } =
    props;
  const { filters = {} } = state;
  const {
    binLocations,
    endDate,
    selectedAssembly,
    startDate,
    tag,
  } = filters;
  const { customs, organization } = useSelector(
    (state) => state.organization,
    isEqual
  );
  //get classifications from redux store
  const { classifications = {} } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const classificationsList = Object.values(classifications || {})

  const [canSubmit, setCanSubmit] = useState(true);

  function renderSearchValues() {
    if (!isItemAnalysis) {
      searchHistories({ ...props, isInventory: true, skip: 0 }, state).then(
        (res) => {
          //filter results based on selected assembly.
          if (selectedAssembly?.value) {
            const resFilteredByAssembly = res?.assetHistories.filter(
              (asset) => {
                return asset.assemblyId === selectedAssembly.value;
              }
            );
            setState((prevState) => ({
              ...prevState,
              assetHistories: resFilteredByAssembly,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              assetHistories: res?.assetHistories,
              realCount: res?.count,
            }));
          }
        }
      );
    } else {
      searchAssets({ ...props, isInventory: true, skip: 0 }, state).then(
        (res) => {
          // Filter results based on selected assembly
          if (selectedAssembly?.value) {
            const resFilteredByAssembly = res?.assets?.filter((asset) =>
              asset?.assemblies?.includes(selectedAssembly.value)
            );
            setState((prevState) => ({
              ...prevState,
              assetHistories: resFilteredByAssembly,
              realCount: res?.count,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              assetHistories: res?.assets,
              realCount: res?.count,
            }));
          }
        }
      );
    }
  }

  // Used for sorting the options in the select
  function compareFn(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    } else {
      return 0;
    }
  }

  const fieldStyle = { margin: "1rem 0" };

  return (
    <Card>
      <CardContent>
        <Grid container>
          {/* From Date / To Date / Category / Display Button */}
          <Grid item xs={3} sx={{ padding: "0 1rem" }}>
            {/* From Date */}
            <Box sx={fieldStyle}>
              <LXDatePicker
                format="MM/dd/yyyy"
                label="From Date (required)"
                onChange={(date) => {
                  //if endDate is before date, set canSubmit to false. Dont set canSubmit to false if date is the same day as enddate
                  if (
                    date > endDate &&
                    date.toDateString() !== endDate.toDateString()
                  ) {
                    setCanSubmit(false);
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, startDate: date },
                    }));
                  } else {
                    //user can only submit if both dates are valid
                    setCanSubmit(
                      isValidDateObject(date) && isValidDateObject(endDate)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, startDate: date },
                    }));
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: !canSubmit && "Invalid date",
                    InputLabelProps: {
                      shrink: false,
                      style: { color: "transparent" },
                    },
                    label: "From Date",
                    size: "large",
                  },
                }}
                value={startDate}
              />
            </Box>
            {/* To Date */}
            <Box sx={fieldStyle}>
              <LXDatePicker
                format="MM/dd/yyyy"
                label="To Date (required)"
                onChange={(date) => {
                  //if enddate is before date, set canSubmit to false. Dont set canSubmit to false if date is the same day as enddate
                  if (
                    date < startDate &&
                    date.toDateString() !== startDate.toDateString()
                  ) {
                    setCanSubmit(false);
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, endDate: date },
                    }));
                  } else {
                    //user can only submit if both dates are valid
                    setCanSubmit(
                      isValidDateObject(date) && isValidDateObject(startDate)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, endDate: date },
                    }));
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: !canSubmit && "Invalid date",
                    InputLabelProps: {
                      shrink: false,
                      style: { color: "transparent" },
                    },
                    label: "To Date",
                    size: "large",
                  },
                }}
                value={endDate}
              />
            </Box>

            {/* Tag ID */}
            <Box sx={fieldStyle}>
              <SimpleTextField
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      tag: event.target.value,
                    },
                  }))
                }
                fullWidth
                size="large"
                value={tag}
                label="Tag Id"
              />
            </Box>

            {/* Display Button */}
            <Box sx={{ marginTop: "1rem" }}>
              <MaterialUiButton
                label="Display"
                data-testid="Display Button"
                onClick={() => renderSearchValues()}
              />
            </Box>
          </Grid>

          {/* Row/Bin / Event */}
          <Grid item xs={3} sx={{ padding: "0 1rem" }}>

            {/* Event */}
            {isItemAnalysis ? null : (
              <EventAndUserFilters
                compareFn={compareFn}
                fieldStyle={fieldStyle}
                organization={organization}
                setState={setState}
                state={state}
              />
            )}

            {/* Row/Bin */}
            <Box sx={fieldStyle}>
              <BinSelector
                apiUrl={apiUrl}
                isInventory={true}
                isItemAnalysis={isItemAnalysis}
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: { ...prevState.filters, binLocations: event },
                  }))
                }
                organizationId={organizationId}
                size="large"
                showCheckboxes={false}
                token={token}
                value={binLocations}
              />
            </Box>

            {/* Assembly  /  */}

            {/* We are commenting this out now as we need to further develop assemblies in the future */}
            {/* <Box sx={fieldStyle}>
              <AssemblySelector
                apiUrl={apiUrl}
                label="Assembly"
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: { ...prevState.filters, selectedAssembly: event },
                  }))
                }
                organizationId={organizationId}
                showCheckboxes={false}
                token={token}
                value={selectedAssembly}
              />
            </Box> */}


          </Grid>

          {/* Classification Filters */}
          <ClassificationFilters
            classifications={classificationsList}
            state={state}
            setState={setState}
          />

          {/* Custom Filters */}
          <CustomFilters
            customs={customs}
            state={state}
            setState={setState}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
