import Autocomplete from "@mui/material/Autocomplete";
import MuiSVGIcon from "../../IconPicker/MuiSVGIcon";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import { InputLabel, TextField } from "@mui/material";
import { ReactComponent as AsteriskSolidIcon } from "../../../assets/faIcons/asterisk-solid.svg";

export default function AutocompleteSelect(props) {
  const {
    defaultLabel = "",
    disabled = false,
    error,

    // Yer probably not gonna need to define this when importing this component
    getOptionLabel = (option) => option.id,
    id,
    key,
    label,
    labelPlacement = "top",
    inputProps,
    labelStyles = {},
    margin,
    multiple = false,
    onChange,
    onClear,
    options,

    // If present a placeholder will be added as an option with the value of ""
    placeHolderText,
    readOnly,
    required,
    size = "small",
    style,
    type,
    value = [],
    variant = "outlined",
  } = props;
  const theme = useTheme()
  const { palette = {} } = theme
  const { typography = {} } = palette
  const formattedLabel = label?.replace(/\s+/g, "");

  let labelWithAstrisk;
  if (required) {
    labelWithAstrisk = (
      <Box sx={{ display: "flex" }}>
        {label}
        <MuiSVGIcon
          icon={<AsteriskSolidIcon/>}
          sx={{
            color: "red",
            fontSize: "10px",
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      key={key}
      sx={
        labelPlacement === "start"
          ? {
            display: "flex",
            alignItems: "center",
          }
          : {}
      }
    >
      {label ? (
        <Box
          sx={{
            color: typography?.secondary,
            display: "flex",
            fontFamily: "Lato",
            fontWeight: "bold !important",
            justifyContent: "space-between",
            lineHeight: 1,
            marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
            marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
            marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
            ...labelStyles,
          }}
        >
          <InputLabel
            id={formattedLabel}
            sx={{
              color: typography?.secondary,
              fontFamily: "Lato",
              fontWeight: "bold",
            }}
          >
            {required ? labelWithAstrisk : label}
          </InputLabel>
          {onClear ? (
            <Typography
              className={value?.length > 0 ? "pointer" : ""}
              onClick={onClear}
              sx={{ opacity: value?.length > 0 ? 1 : 0.2 }}
              variant="caption"
            >
              Clear
            </Typography>
          ) : null}
        </Box>
      ) : null}
      <FormControl
        className="simple-select"
        error={error}
        size={size}
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        <Autocomplete
          disabled={disabled}
          id={id}
          inputProps={{ readOnly: readOnly, ...inputProps }}
          data-cypress-id={inputProps?.["data-cypress-id"]}
          displayEmpty={placeHolderText ? true : false}
          labelId={formattedLabel}
          margin={margin}
          MenuProps={{ disablePortal: true }}
          multiple={multiple}
          options={options}
          onChange={(event, value) => onChange(event, value)}
          getOptionLabel={(option) => getOptionLabel(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              helperText={error ? "Required Field" : ""}
              size={size}
              variant={variant}
            >
              {defaultLabel}
            </TextField>
          )}
          required={required}
          style={style}
          type={type}
          value={value}
        />
      </FormControl>
    </Box>
  );
}
