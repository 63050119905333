import { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useSelector } from "react-redux";
import GoogleMapComponent from "../../../../components/Maps/GoogleMapComponent";
import MapDisplayWidgetSettings from "./MapDisplayWidgetSettings";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { searchAssets } from "../../../../utils/API/Assets/Assets";

export default function MapDisplay(props) {
  const { timeZone, userRoles } = props;
  const [mapSettings, setMapSettings] = useState({
    center: { lat: 34.561187, lng: -40.814914 },
    zoom: 2,
    viewSettings: false,
  });
  const {
    "View Inventory": viewInventory = null,
    "View Asset History": viewAssetHistory = null,
  } = userRoles;
  const defaultTableValue = viewInventory
    ? "Inventory"
    : viewAssetHistory
    ? "Asset"
    : null;

  const [state, setState] = useState({
    assets: [],
    filters: {
      assetType: defaultTableValue,
      startDate: new Date(),
      endDate: new Date(),
    },
    isInitialized: false,
  });
  const { assets = [], isInitialized = false } = state;

  const [activeMarker, setActiveMarker] = useState(null);

  const { facilities } = useSelector((state) => state.organization, isEqual);

  const popUpContent = (marker) => {
    const {
      facilityId = null,
      lastEvent = null,
      latitude = 0,
      longitude = 0,
      propertiesMap = {},
      tag = null,
      timeOfLogLong = null,
    } = marker;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {tag ? (
          <Typography component="h2" sx={{ fontSize: "22px" }}>
            {tag}
          </Typography>
        ) : (
          ""
        )}
        {lastEvent ? (
          <Typography component="span" sx={{ fontSize: "22px" }}>
            {lastEvent}
          </Typography>
        ) : (
          ""
        )}{" "}
        {propertiesMap && propertiesMap.note ? (
          <Typography component={"span"} sx={{ fontSize: "18px" }}>
            Note: {propertiesMap.note}
          </Typography>
        ) : (
          ""
        )}
        {timeOfLogLong ? (
          <Typography component={"span"} sx={{ fontSize: "14px" }}>
            Date: {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)}
          </Typography>
        ) : null}
        {facilityId &&
        facilities[facilityId] &&
        latitude !== 0 &&
        longitude !== 0 ? (
          <Typography component={"span"} sx={{ fontSize: "14px" }}>
            Facility: {facilities[facilityId].name}
          </Typography>
        ) : (
          ""
        )}{" "}
      </Box>
    );
  };

  // This fires off to initially populate the map with markers
  useEffect(() => {
    if (!isInitialized) {
      searchAssets({ ...props }, state).then((res) => {
        if (res.success) {
          setState((prevState) => {
            return {
              ...prevState,
              assets: res.assets,
              isInitialized: true,
            };
          });
        }
      });
    }
  }, [isInitialized, props, state]);

  return (
    <Card sx={{ height: "100%", minHeight: "50vh" }}>
      <CardContent sx={{ height: "100%", minHeight: "50vh" }}>
        {mapSettings.viewSettings ? (
          <MapDisplayWidgetSettings
            {...props}
            setState={setState}
            state={state}
            setMapSettings={setMapSettings}
          />
        ) : (
          <GoogleMapComponent
            activeMarker={activeMarker}
            additionalMapItems={
              <SettingsApplicationsIcon
                className="pointer"
                onClick={() =>
                  setMapSettings((prevState) => ({
                    ...prevState,
                    viewSettings: !prevState.viewSettings,
                  }))
                }
                sx={{
                  left: "10px",
                  position: "absolute",
                  top: "10px",
                  zIndex: 5,
                }}
              />
            }
            markersToRender={assets}
            markerKey="assetId"
            mapSettings={mapSettings}
            options={{ mapTypeControl: false }}
            popUpContent={popUpContent}
            setActiveMarker={setActiveMarker}
          />
        )}
      </CardContent>
    </Card>
  );
}
