import MaterialUiDataGridPremium from '../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium';
import buildColumns from '../inventoryHistory/Table/columns';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Grid } from '@mui/material';

function DailyEventsGrid(props) {
  const {
    apiUrl,
    appUserType,
    eventsGridRef,
    handleSave,
    selectedBar,
    selectedDayEvents,
    setState,
    state,
    token,
    userId,
  } = props;

  const {
    classifications,
    customs,
    facilities,
    zones,
  } = useSelector((state) => state.organization, isEqual);

  const history = useHistory();
  const timeZone = props.timeZone?.value || null;
  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const specificConfig = displayConfigs.find(
    (element) => element.type === "DailyHistory"
  );
  const columnOrder = specificConfig?.columns || null;

  const { historyTableColumns } = buildColumns({
    apiUrl,
    appUserType,
    classifications,
    columnOrder,
    customs,
    displayColumnOptions: state?.filters?.displayColumnOptions || {},
    facilities,
    handleSave,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  return (
    <Grid 
      item 
      xs={12} 
      aria-labelledby={selectedBar.date}
      id='daily-events-table'
      ref={eventsGridRef}
      role='tabpanel'
      tabIndex={0}
    >
      <MaterialUiDataGridPremium
        autoHeight={false}
        checkboxSelection={false}
        columns={historyTableColumns}
        disableAggregation={false}
        exportFileName={"Inventory-Daily"}
        getRowId={(row) => row.assetHistoryId}
        rows={selectedDayEvents}
      />
    </Grid>
  )
}

export default DailyEventsGrid