import { useEffect, useState } from "react";
import { isArray } from "lodash";
import { naturalSort } from "../../utils/naturalSort";
import { Tooltip, useTheme } from "@mui/material";
import { validatePasswordStrength } from "../../utils/passwordStrength";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import moment from "moment-timezone";
import SimpleCheckbox from "../../components/Forms/FieldTypes/Checkbox";
import SimpleSelect from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";

export default function EditUser(props) {
  const theme = useTheme();
  const {
    consoleRoles: availableConsoleRoles = {},
    dispatchGlobal,
    isCreate,
    isMainOrg,
    mobileRoles: availableMobileRoles = {},
    onHide,
    readOnly,
    selectedUser = {},
    setChildUsersMap,
  } = props;

  const classes = {
    addOrganizationButton: {
      alignItems: "center",
      display: "flex",
      marginTop: "1rem",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    control: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    paper: {
      height: 140,
      width: 100,
    },
    root: {
      flexGrow: 1,
      paddingBottom: "1rem",
      paddingTop: "1rem",
    },
    select: {
      marginBottom: "1rem",
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  };

  const [state, setState] = useState({
    email: { error: false, id: "email", value: "" },

    // Im leaving these named as "consoleRoles2". This needs to be renamed to consoleRoles.
    // But this currently causes confusion between the users consoleRole and the propertiesMap.consoleRole.
    // We are currently working to remove the propertiesMap.consoleRole. So this is a temp fix until then.
    consoleRoles2: { error: false, id: "consoleRoles2", value: [] },
    firstName: { error: false, id: "firstName", value: "" },
    jobTitle: { error: false, id: "jobTitle", value: "" },
    lastName: { error: false, id: "lastName", value: "" },
    memberOf: [],
    mfaStatus: {
      error: false,
      id: "mfaStatus",
      mobileMfaSettings: {},
      value: false,
    },
    mobileRoles2: { error: false, id: "mobileRoles2", value: [] },
    notes: { error: false, id: "notes", value: "" },
    organization: { error: false, id: "organization", value: "" },
    password: { error: false, id: "password", value: "" },
    phone: { error: false, id: "phone", value: "" },
    readOnly: false,
    resetPassword: false,
    userType: { error: false, id: "userType", value: "" },
  });
  const {
    consoleRoles2,
    email,
    firstName,
    jobTitle,
    lastName,
    mobileRoles2,
    notes,
    password,
    phone,
    resetPassword = false,
    userType,
  } = state;
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);

  function onChange(event, isCheckbox = false) {
    setState((prevState) => ({
      ...prevState,
      [event.target.id]: {
        ...prevState[event.target.id],
        error: false,
        id: event.target.id,
        value: !isCheckbox ? event.target.value : event.target.checked,
      },
    }));
  }

  function handlePasswordOnChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.id]: {
        error: false,
        id: event.target.id,
        value: event.target.value.trim(),
      },
    }));
  }

  function buildPassword() {
    const passwordLength = 13;

    let lowerChars = "abcdefghijklmnopqrstuvwxyz";
    let numberChars = "0123456789";
    let specialChars = "!@#$%&?*";
    let upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let allChars = numberChars + upperChars + lowerChars + specialChars;

    let randPasswordArray = Array(passwordLength);

    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;

    randPasswordArray = randPasswordArray.fill(allChars, 4);

    const a = shuffleArray(
      randPasswordArray.map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
    ).join("");

    setState((prevState) => ({
      ...prevState,
      password: { error: false, id: "password", value: a },
    }));
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [email, firstName, lastName, userType];

    if (resetPassword && !validatePasswordStrength(password.value)) {
      setShowPasswordAlert(true);
      return null;
    }

    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;

      if (id === "email") {
        // Ripped from: https://bobbyhadz.com/blog/react-check-if-email-is-valid
        // Quick test to see if the email is valid. Returns a boolean
        const isValidEmail = /\S+@\S+\.\S+/.test(value);

        // Here we do a weak check to see if the email is valid
        if (!isValidEmail) {
          isValidated = false;
          setState((prevState) => ({
            ...prevState,
            [id]: { ...prevState[id], error: true },
          }));
        }
      }

      // Here we check to verify there is content entered in
      if (!value || value.length === 0) {
        isValidated = false;
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true },
          };
        });
      }
    });

    if (isValidated) {
      handleSubmit();
    }
  }

  function handleSubmit() {
    const browserTimeZone = moment.tz.guess();
    const {
      apiUrl,
      selectedUser = {},
      notificationModal,
      onHide,
      onSuccess,
      selectedOrganization,
      token,
      updateUsersMap,
    } = props;
    const { organizationId = "" } = selectedOrganization;
    const {
      email,
      firstName,
      lastName,
      password,
      propertiesMap,
      resetPassword,
      userType,
    } = state;
    const { appUserId } = selectedUser;

    let grantConsoleAccess = true;
    consoleRoles2.value.forEach((role) => {
      if (
        role.name === "No Console Access" ||
        role.name === "No LXConnect Access" ||
        role.name === "Mobile Only"
      ) {
        grantConsoleAccess = false;
      }
    });

    let grantMobileAccess = true;
    mobileRoles2.value.forEach((role) => {
      if (role.name === "No Mobile Access") {
        grantMobileAccess = false;
      }
    });

    const fetchUrl =
      !isMainOrg && isCreate
        ? `${apiUrl}organizations/${organizationId}/appUsers`
        : isCreate
        ? `${apiUrl}appUsers`
        : `${apiUrl}appUsers/${appUserId}`;

    let body = {
      appUserId,

      // Contacts is going to be re-worked. So we are commenting this out for now
      // contacts: [{ type: "phone", value: phone.value }],
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      permissions: ["console", "mobile"],
      propertiesMap,
      // Need to ask Todd what the migration strat is.
      // roles: [consoleRole.value, mobileRole.value],
      userRoles: [
        {
          name: "console",
          roles: consoleRoles2.value,
        },
        {
          name: "mobile",
          roles: mobileRoles2.value,
        },
      ],
    };

    // Sets the users time zone
    propertiesMap.timeZone = {
      label: browserTimeZone,
      useDefaultTimeZone: true,
      value: browserTimeZone,
    };

    // Sets the users console user type, e.g., "Product" or "Asset" or "Asset/Product". This is still stored as consoleRole in the users' propertiesMap
    propertiesMap.consoleRole = userType.value;

    // set user job title, memberOf, notes, phone number...
    propertiesMap.jobTitle = state.jobTitle.value;
    propertiesMap.memberOf = state.memberOf;
    propertiesMap.phone = state.phone.value;
    propertiesMap.notes = state.notes.value;

    // Sets the password in the request body if reset password is true
    if (resetPassword === true) {
      body.password = password.value;
    }

    // Deals with the situation that a user selects no console and no mobile access
    if (!grantConsoleAccess && !grantMobileAccess) {
      notificationModal(
        "User may not simultaneously have 'Mobile Only/Console No Access' and 'Mobile No Access'. Please change roles",
        "error"
      );
      return null;
    }

    //if the user has no console access, we need to remove the console role from permissions
    if (!grantConsoleAccess) {
      body.permissions = body.permissions.filter((permission) => {
        return permission === "console" ? false : true;
      });
    }

    //if the user has no mobile access, we need to remove the mobile role from permissions
    if (!grantMobileAccess) {
      body.permissions = body.permissions.filter((permission) => {
        return permission === "mobile" ? false : true;
      });
    }

    fetch(fetchUrl, {
      method: isCreate ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // Are we updating a user from the main org or a user from a child organization?
          if (isMainOrg) {
            dispatchGlobal(
              updateUsersMap({
                appUserId: response.appUser?.appUserId,
                body: response.appUser,
              })
            );
          } else {
            setChildUsersMap((prevState) => {
              return {
                ...prevState,
                [response.appUser?.appUserId]: response.appUser,
              };
            });
          }
          onHide();
          notificationModal("User Successfully Updated");
          onSuccess();
        } else {
          notificationModal(`Error: ${response.error}`, "error");
        }
      });
  }

  // function handleMFA() {
  //   const {mobileMfaSettings = {}} = mfaStatus
  //   let body = {
  //     multiFactorAuthConfigs: [
  //       mobileMfaSettings,
  //       {
  //         type: "DUO",
  //         role: "Admin",
  //         requiresMFA: !mfaStatus.value,
  //         isActive: !mfaStatus.value,
  //       },
  //     ],
  //   };

  //   // We need to do the call to the API to enable/disable MFA
  //   editAppUser({ apiUrl, token, userId: selectedUser.appUserId }, body).then(
  //     (res) => {
  //       const { appUser = {}, success = false } = res;
  //       if (success) {
  //         const { multiFactorAuthConfigs = [] } = appUser;
  //         const mfaStatus = multiFactorAuthConfigs.find(
  //           (config) => config.role === "Admin"
  //         );
  //         const { isActive = false } = mfaStatus || {}

  //         if(isActive){
  //           generateDuoMFAuthenticationUrl({
  //             apiUrl,
  //             token,
  //             username: selectedUser?.email,
  //           }).then((res) => {
  //             const { success = false, duoResults = {} } = res;
  //             const { authenticationUrl = "" } = duoResults;

  //             if (success) {
  //               window.location.href = authenticationUrl;
  //             }
  //           });
  //         } else {
  //         // Leavin this blank. We will probably want some sort of flow here.
  //         }
  //       }
  //     }
  //   );

  //   // The user will then be re-directed back to the console to the MFA page.
  // }

  // This grabs either a selected user and throws their stuff into state.
  // Or if isCreate is true, it sets up a new user.

  useEffect(() => {
    const {
      email = "",
      firstName = "",
      lastName = "",
      multiFactorAuthConfigs = [],
      propertiesMap = {},
      roles = [],
      userRoles = [],
    } = selectedUser;

    const consoleRoles2 =
      userRoles.find((role) => role?.name === "console")?.roles || [];
    const mobileRoles2 =
      userRoles.find((role) => role?.name === "mobile")?.roles || [];

    if (isCreate) {
      consoleRoles2.push({ name: "Standard" });
      mobileRoles2.push({ name: "Mobile" });
    }

    // userType, e.g., "Asset", "Product", or "Asset/Product", is called consoleRole in the propertiesMap
    const {
      consoleRole: userType = "",
      jobTitle = "",
      memberOf = [""],
      notes = "",
      phone = "",
    } = propertiesMap;

    const consoleMfaSettings =
      multiFactorAuthConfigs.find((config) => config.role === "Admin") || null;
    const mobileMfaSettings =
      multiFactorAuthConfigs.find((config) => config.role === "Mobile") || null;
    const isMfaEnabled = consoleMfaSettings?.isActive || false;

    // console and mobile roles are stored in the roles array on main user column
    const usersConsoleRole = roles.filter(
      (role) => availableConsoleRoles[role]
    )[0];
    const usersMobileRole = roles.filter(
      (role) => availableMobileRoles[role]
    )[0];

    const memberOfFormatted = isArray(memberOf) ? memberOf : [memberOf];

    setState((prevState) => ({
      ...prevState,
      consoleRole: { error: false, id: "consoleRole", value: usersConsoleRole },
      consoleRoles2: {
        error: false,
        id: "consoleRoles2",
        value: consoleRoles2,
      },
      email: { error: false, id: "email", value: email },
      firstName: { error: false, id: "firstName", value: firstName },
      jobTitle: { error: false, id: "jobTitle", value: jobTitle },
      lastName: { error: false, id: "lastName", value: lastName },
      memberOf: memberOfFormatted,
      mfaStatus: {
        error: false,
        id: "mfaStatus",
        mobileMfaSettings,
        value: isMfaEnabled,
      },
      mobileRole: { error: false, id: "mobileRole", value: usersMobileRole },
      mobileRoles2: { error: false, id: "mobileRoles2", value: mobileRoles2 },
      notes: { error: false, id: "notes", value: notes },
      password: { error: false, id: "password", value: "" },
      phone: { error: false, id: "phone", value: phone },
      propertiesMap,
      userType: { error: false, id: "userType", value: userType },
    }));
  }, [
    availableConsoleRoles,
    availableMobileRoles,
    isCreate,
    props,
    selectedUser,
  ]);

  return (
    <Grid container sx={classes.root} spacing={2}>
      {/* First Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={firstName.error}
          id="firstName"
          inputProps={{ "data-cypress-id": "users-user-mdl-txt-first-name" }}
          label="First Name"
          onChange={(event) => {
            onChange(event);
          }}
          readOnly={readOnly}
          required
          value={firstName.value}
        />
      </Grid>

      {/* Last Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={lastName.error}
          label="Last Name"
          id="lastName"
          inputProps={{ "data-cypress-id": "users-user-mdl-txt-last-name" }}
          onChange={(event) => {
            onChange(event);
          }}
          required={true}
          readOnly={readOnly}
          value={lastName.value}
        />
      </Grid>

      {/* Email */}
      <Grid item xs={6}>
        <SimpleTextField
          error={email.error}
          errorText={
            email.value?.length === 0 ? "Required Field" : "Invalid Email"
          }
          label="Email"
          id="email"
          inputProps={{ "data-cypress-id": "users-user-mdl-txt-email" }}
          onChange={(event) => {
            onChange(event);
          }}
          required={true}
          readOnly={readOnly}
          value={email.value}
        />
      </Grid>

      {/* Phone Number */}
      <Grid item xs={6}>
        <SimpleTextField
          label="Phone Number"
          id="phone"
          inputProps={{ "data-cypress-id": "users-user-mdl-txt-phone" }}
          onChange={(event) => {
            onChange(event);
          }}
          readOnly={readOnly}
          value={phone.value}
        />
      </Grid>

      {/* Job Title */}
      <Grid item xs={6}>
        <SimpleTextField
          label="Job Title"
          id="jobTitle"
          inputProps={{ "data-cypress-id": "users-user-mdl-txt-job-title" }}
          onChange={(event) => {
            onChange(event);
          }}
          readOnly={readOnly}
          value={jobTitle.value}
        />
      </Grid>

      {/* User's type, e.g., Product, Asset, Asset/Product */}
      <Grid item xs={6}>
        <SimpleSelect
          error={userType.error}
          label="User Type"
          id="userType"
          inputProps={{ "data-cypress-id": "users-user-mdl-select-user-type" }}
          onChange={(event) => {
            onChange({ target: { id: "userType", value: event.target.value } });
          }}
          readOnly={readOnly}
          value={userType.value}
          options={[
            { value: "Asset-Operations", label: "Asset-Operations" },
            { value: "Asset/Product", label: "Asset/Product" },
            { value: "Asset", label: "Asset-Warehouse" },
            { value: "Product", label: "Product" },
            { value: "Asset/Inventory", label: "Asset/Inventory" },
            { value: "Warehouse-Operations", label: "Warehouse-Operations" },
            { value: "Inventory", label: "Inventory" },
            { value: "Inventory/Product", label: "Inventory/Product" },
          ]}
          required={true}
          variant="outlined"
        />
      </Grid>

      {/* User's LXConnect Role, e.g., Admin, Lite, etc*/}
      <Grid item xs={6}>
        {consoleRoles2?.value?.map((role, index) => (
          <SimpleSelect
            label="LXConnect Role"
            id={`console-${role.name}-${index}`}
            inputProps={{
              "data-cypress-id": "users-user-mdl-select-console-role",
            }}
            key={`${role.name}-${index}`}
            onChange={(event) => {
              setState((prevState) => {
                const newValue = [...prevState.consoleRoles2.value];

                newValue[index] = {
                  ...newValue[index],
                  name: event.target.value,
                };

                return {
                  ...prevState,
                  consoleRoles2: {
                    ...prevState.consoleRoles2,
                    value: newValue,
                  },
                };
              });
            }}
            readOnly={readOnly}
            value={role.name}
            options={Object.keys(availableConsoleRoles)
              .map((element) => {
                if (element === "No Console Access") {
                  return { label: "No LXConnect Access", value: element };
                }
                return { label: element, value: element };
              })
              .sort((a, b) => naturalSort(a.label, b.label))}
            required={true}
            variant="outlined"
          />
        ))}
      </Grid>

      {/* Mobile */}
      <Grid item xs={6}>
        {mobileRoles2?.value?.map((role, index) => (
          <SimpleSelect
            id={`mobile-${role.name}-${index}`}
            inputProps={{
              "data-cypress-id": "users-user-mdl-select-console-role",
            }}
            key={`${role.name}-${index}`}
            label="Mobile Role"
            onChange={(event) => {
              setState((prevState) => {
                const newValue = [...prevState.mobileRoles2.value];

                newValue[index] = {
                  ...newValue[index],
                  name: event.target.value,
                };

                return {
                  ...prevState,
                  mobileRoles2: {
                    ...prevState.mobileRoles2,
                    value: newValue,
                  },
                };
              });
            }}
            options={Object.keys(availableMobileRoles)
              .map((element) => {
                return { label: element, value: element };
              })
              .sort((a, b) => naturalSort(a.label, b.label))}
            readOnly={readOnly}
            required={true}
            value={role.name}
            variant="outlined"
          />
        ))}
      </Grid>

      {/* Notes */}
      <Grid item xs={12}>
        <SimpleTextField
          label="Notes"
          id="notes"
          inputProps={{ "data-cypress-id": "users-user-mdl-txt-notes" }}
          multiline
          onChange={(event) => {
            onChange(event);
          }}
          readOnly={readOnly}
          rows={4}
          value={notes.value}
        />
      </Grid>

      {/* Generate new password checkbox / MFA - Only shows up when isEdit is true */}
      {!readOnly && !isCreate ? (
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <SimpleCheckbox
            name="generate-password"
            inputProps={{
              "data-cypress-id": "users-user-mdl-cbx-generate-password",
            }}
            label="Generate New Password"
            checked={resetPassword}
            onChange={() => {
              buildPassword();
              setState((prevState) => ({
                ...prevState,
                resetPassword: !prevState.resetPassword,
              }));
            }}
          />

          {/* Multi-Factor Authorization - should only be visible to the current user */}

          {/* We initially built this with the idea that the user could conditionally opt into MFA.
          Our current needs states that MFA is either on for the org or off. Thus the user doesnt need the ability to
          toggle MFA. We are leaving this here as users are able to use their own custom MFA if needed.
          In this case a 'opt in' option might exist. So leaving this here for now. */}

          {/* {userId === selectedUser?.appUserId ? <SimpleSwitch
            checked={mfaStatus?.value}
            id="mfaStatus"
            label="Multi-Factor Authorization"
            onChange={(event) => {
              onChange(event, true)
              handleMFA();
            }}
          /> : null} */}
        </Grid>
      ) : null}

      {/* Generate new password field */}
      {resetPassword ? (
        <Grid item xs={12}>
          <Tooltip
            open={showPasswordAlert}
            title={
              <>
                The requirements for a password are: <br />- At least 8
                characters <br />- A mixture of both uppercase and lowercase
                letters <br />- A mixture of letters and numbers <br />-
                Inclusion of at least one special character, e.g., ! @ # ? ]{" "}
              </>
            }
          >
            <div>
              <SimpleTextField
                id="password"
                inputProps={{
                  "data-cypress-id": "users-user-mdl-txt-password",
                }}
                label="Password"
                onChange={(event) => {
                  // Handles the tooltip for password
                  if (
                    resetPassword &&
                    validatePasswordStrength(event.target.value)
                  ) {
                    setShowPasswordAlert(false);
                  } else if (
                    resetPassword &&
                    !validatePasswordStrength(event.target.value)
                  ) {
                    setShowPasswordAlert(true);
                  }

                  handlePasswordOnChange(event);
                }}
                readOnly={readOnly}
                required
                value={password.value}
              />
            </div>
          </Tooltip>
        </Grid>
      ) : null}

      {/* Cancel and Submit Buttons */}
      {!readOnly ? (
        <Grid sx={classes.buttonContainer} item xs={12}>
          {/* Cancel Button */}
          <Grid item xs={4}>
            <MaterialUiButton
              color="cancel"
              cypressId="users-user-mdl-btn-cancel"
              fullWidth={true}
              label="Cancel"
              onClick={() => {
                onHide();
              }}
              variant="outlined"
            />
          </Grid>

          {/* Submit */}
          <Grid item xs={4}>
            <MaterialUiButton
              color="submit"
              cypressId="users-user-mdl-btn-submit"
              fullWidth={true}
              label="Submit"
              onClick={validateFields}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
