import { useHistory } from "react-router-dom";
import buildColumns from "./columns";
import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";
import moment from "moment";

export default function Table(props) {
  const {
    apiUrl,
    appUserType,
    classifications,
    customs,
    facilities,
    handleSave,
    setState,
    state,
    token,
    toolBarButtons,
    userId,
    useSelector,
    zones,
  } = props;

  const history = useHistory();
  const timeZone = props.timeZone?.value || null;
  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const specificConfig = displayConfigs.find(
    (element) => element.type === "AssetHistory"
  );
  const columnOrder = specificConfig?.columns || null;

  const { historyTableColumns } = buildColumns({
    apiUrl,
    appUserType,
    classifications,
    columnOrder,
    customs,
    displayColumnOptions: state.filters?.displayColumnOptions || {},
    facilities,
    handleSave,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  function handleRowSelect(selectedRowIds) {
    let updatedSelectedAssets = {};

    const assetsList = state.histories || [];
    for (let asset of assetsList) {
      for (let rowId of selectedRowIds) {
        if (asset.assetHistoryId === rowId) {
          updatedSelectedAssets = {
            ...updatedSelectedAssets,
            [asset.assetHistoryId]: asset,
          };
        }
      }
    }

    setState((s) => {
      return {
        ...s,
        selectedAssets: updatedSelectedAssets,
      };
    });
  }

  const currentTime = moment().tz(timeZone && timeZone.value ? timeZone.value : timeZone ? timeZone : moment.tz.guess()).format("YYYY-MM-DD");
  const exportFileName = `Asset_History_${currentTime}`;

  return (
    <>
      <MaterialUiDataGridPremium
        autoHeight={false}
        checkboxSelectedIds={Object.keys(state.selectedAssets)}
        checkboxSelection={true}
        columns={historyTableColumns}
        disableCsvExportOption={false}
        delayedLoad={state.histories?.length === 0 ? false : true}
        exportFileName={exportFileName}
        getRowId={(row) => row.assetHistoryId}
        handleRowSelect={handleRowSelect}
        loading={false}
        needsCustomToolbar={true}
        rowCount={state.histories?.length}
        rows={state.histories || []}
        showGridToolBar={true}
        toolBarButtons={toolBarButtons}
      />
    </>
  );
}
