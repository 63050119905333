import {
  useEffect,
  useRef,
  useState,
} from 'react'
import { getDateXDaysAgo, getPreviousNDays, toMMDDYYYYFormat } from '../../../globalStore/utils/helpers'
import { Grid } from '@mui/material'
import { thunks } from '../../../globalStore/slices/inventoryHistory/inventoryHistorySlice'
import { useDispatch } from 'react-redux'
import DailyEventsBar from './DailyEventsBar'
import DailyEventsGrid from './DailyEventsGrid'
import moment from 'moment';

function DailyEvents(props) {

  const eventsGridRef = useRef(null);

  const [recentEvents, setRecentEvents] = useState([]);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]);
  // make sure date format is the same as the date format in the events data (05/10/2023)
  const todaysDateFormatted = toMMDDYYYYFormat(new Date())
  const [selectedBar, setSelectedBar] = useState({ date: todaysDateFormatted, eventType: '' })

  const { searchRecentHistories } = thunks.inventoryHistory
  const dispatchGlobal = useDispatch()

  const todaysDate = new Date()
  const pastDate = getDateXDaysAgo(todaysDate, 28)

  const rangeStartTime = pastDate.getTime()
  const rangeEndTime = todaysDate.getTime()

  //newest date to left
  // const last28Days = getPreviousNDays(new Date(), 28)
  //newest date to the right
  const last28Days = getPreviousNDays(new Date(), 28).slice().reverse()

  useEffect(() => {
    // api call to get events for last 28 days
    // going to need the time range for the last 28 days and the organization id as well as the token
    dispatchGlobal(searchRecentHistories({ rangeStart: rangeStartTime, rangeEnd: rangeEndTime })).then((res) => {
      setRecentEvents(res.payload.recentHistories)
    }
    )
  }, [])

  //every time selectedBar changes, check if there is a date and eventType, if so, get events for that date and eventType, if not, get events for selectedDate
  useEffect(() => {
    if (selectedBar.date && selectedBar.eventType) {
      getEventsForSelectedEventType()
    }
    else {
      getAllEventsForSelectedDay()
    }

  }, [selectedBar])

  function getAllEventsForSelectedDay() {
    // filter recentEvents for events that match the selected date
    const filteredEvents = recentEvents.filter(event => {
      return event.timeOfLogString === toMMDDYYYYFormat(selectedBar.date)
    })
    setSelectedDayEvents(filteredEvents)
  }

  function getEventsForSelectedEventType() {
    const filteredEvents = recentEvents.filter(event => {
      return event.timeOfLogString === toMMDDYYYYFormat(selectedBar.date) && event.event === selectedBar.eventType
    })
    setSelectedDayEvents(filteredEvents)
  }

  function configureBarDatasets() {
    const possibleEvents = {}

    let eventType;
    let eventDate;
    let dropOffIndex;
    let newEventValue;

    recentEvents.forEach(event => {

      eventType = event.event
      eventDate = event.timeOfLogString
      dropOffIndex = last28Days.indexOf(eventDate)

      //if possibleEvents doesnt have the event type, fill an array with 28 0s and at the dropOffIndex(date which event happened), put a 1
      if (possibleEvents[eventType] === undefined) {
        possibleEvents[eventType] = new Array(28).fill(0)
        possibleEvents[eventType][dropOffIndex] = 1
      }
      else if (possibleEvents[eventType]) {
        newEventValue = possibleEvents[eventType][dropOffIndex]
        // handles case where the event type is already in the possibleEvents object but the value is undefined
        if (newEventValue === undefined) {
          possibleEvents[eventType][dropOffIndex] = 1
        }
        else { possibleEvents[eventType][dropOffIndex] = newEventValue + 1 }
      }
      else {
        console.warn("Unaccounted for scenario with getting daily events for bar chart")
      }
    })

    return possibleEvents
  }

  function configureBarDatasets2() {
    // series is an object so we can easily check if something is here or not.
    let series = []
    let data = []

    // Here we prep the data array - I bet theres a better way to utilize moment.js to get this info. But this works for now
    last28Days.forEach((date) => {
      data.push({
        date,
        formatedDate: moment(date, "MM/DD/YYYY").format("MM/DD")
      })
    })

    // Here we then check recentEvents to fill in the rest of data and the series arrays
    recentEvents.forEach(event => {
      const eventName = event.event
      const dataEntry = data.find(d => d.date === event.timeOfLogString);
      const seriesEntry = series.find(s => s.dataKey === eventName);

      // Need to check that the date is in the last 28 days
      if (dataEntry) {
        // If so we need to check if the eventName is in the object. If so, incriment by one. If not. Add it and set to 1
        dataEntry[eventName] = dataEntry.eventName ? dataEntry.eventName + 1 : 1

        // We also need to fill out the series object
        if (!seriesEntry) {
          series.push({
            dataKey: eventName, id: eventName, label: eventName, stack: 'assets',
          })
        }
      }
    })

    return { data, series }
  }

  return (
    <Grid
      container
      style={{
        marginTop: "1rem",
        padding: "0 15px",
        margin: "0 auto",
        width: "84vw",
      }}
    >
      <DailyEventsBar
        {...props}
        barDatasets={configureBarDatasets()}
        barDatasets2={configureBarDatasets2()}
        eventsGridRef={eventsGridRef}
        last28Days={last28Days}
        recentEvents={recentEvents}
        selectedBar={selectedBar}
        setSelectedBar={setSelectedBar}
      />

      <DailyEventsGrid
        {...props}
        eventsGridRef={eventsGridRef}
        selectedBar={selectedBar}
        selectedDayEvents={selectedDayEvents}
      />
    </Grid>
  )
}

export default DailyEvents
