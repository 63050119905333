import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function FreshDeskWidget(props) {
  // e.g., Asset-Operations, Asset, Asset-Product, Product
  // NOTE Asset is Asset-Warehouse...
  const userType = useSelector((state) => state.user.appUserType);
  const { path = "" } = props;

  const freshWorksWidget = window.FreshworksWidget;

  // freshWorksWidget("hide");

  useEffect(() => {
    const dashboardArticle =
      // asset warehouse
      userType === "Asset"
        ? "60000316946"
        : // asset operations
        userType === "Asset-Operations"
        ? "60000701337"
        : // product
        userType === "Product"
        ? "60000678710"
        : null;

    const articlePaths = {
      // use user type to dynamically choose which dashboard articles to
      "/": dashboardArticle,
      "/dashboard": dashboardArticle,
      "/home": dashboardArticle,
      "/assetHistory": "60000703307",
      "/assetStatus": "60000672351",
      "/assetSnapshot": "60000691865",
      "/batches": "60000317094",
      "/devices": "60000688085",
      "/batchHistory": "60000669819",
      "/facilities": "60000317179",
      "/generateCQR": "60000669821",
      "/organizations": "60000317223",
      "/products": "60000317008",
      "/settings": "60000317301",
      "/users": "60000317252",
      // login needs to go into it's own, unauthenticated component
      "/login": "60000701045",
    };

    if (articlePaths[path]) {
      freshWorksWidget("show");
      freshWorksWidget("open", "article", {
        id: articlePaths[path],
      });
    }
  }, [path, userType, freshWorksWidget]);

  return <></>;
}
