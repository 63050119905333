import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { downloadCQR } from "./api";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";

export default function DeliverTracking(props) {
  
  const classes = {
    button: {
      marginLeft: ".5rem",
      marginBottom: ".5rem",
    },
    itemText: {
      fontWeight: "bold",
      marginRight: ".25rem",
    },
    textUpperCase: {
      textTransform: "uppercase",
    },
  }
  
  const {
    assetType,
    batchAsset,
    batchName,
    goBack,
    isLoading,
    productName,
    qrBackgroundSession,
    selectedAssets,
    selectedAssetCount,
    selectCQRLayout,
    state,
  } = props;

  const { eccLevel, format, magnification, version } = state;
  const assetId = batchAsset
    ? props.assetId
    : selectedAssets[Object.keys(selectedAssets)[0]]
    ? selectedAssets[Object.keys(selectedAssets)[0]].assetId
    : "";
  return (
    <Grid container id="delivery">
      {/* Information */}
      <Grid item xs={12}>
        <Box pt={5} pb={5} pl={2} pr={2}>
          <Card>
            <CardContent>
              <Grid container>
                {/* Header */}
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography component="h2" variant="h4">Information</Typography>
                  </Box>
                </Grid>

                {/* Sub Header */}
                <Grid item xs={12}>
                  <Box textAlign="center" mb={2}>
                    Please Review All Information and View the CQR Preview
                  </Box>
                </Grid>

                {/* Asset Info */}
                <Grid item xs={12}>
                  {batchAsset ? (
                    <Box display="flex" justifyContent="center">
                      <Box width="25%">
                        {/* Batch Id */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>Batch ID:</Typography>
                          {batchName}
                        </Typography>

                        {/* Product Name */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>
                            Product Name:
                          </Typography>
                          {productName}
                        </Typography>

                        {/* Unit of Measure */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>
                            Unit of Measure:
                          </Typography>
                          {assetType}
                        </Typography>

                        {/* Count */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>Count:</Typography>
                          {selectedAssetCount}
                        </Typography>
                      </Box>
                      <Box width="25%">
                        {/* ECC Level */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>ECC Level:</Typography>
                          Level{" "}
                          <Typography component="span" sx={classes.textUpperCase}>
                            {eccLevel}
                          </Typography>
                        </Typography>

                        {/* Image Format */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>
                            Image Format:
                          </Typography>
                          <Typography component="span" sx={classes.textUpperCase}>
                            {format}
                          </Typography>
                        </Typography>

                        {/* QR Version */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>QR Version:</Typography>
                          {version}
                        </Typography>
                      </Box>
                      <Box width="25%">
                        {/* Qr Size */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>QR Size:</Typography>
                          {magnification} pixel/cell
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="center">
                      <Box width="25%">
                        {/* Count */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>Count:</Typography>
                          {Object.keys(selectedAssets).length}
                        </Typography>

                        {/* Asset Tag */}
                        <Box display="flex" mt={2} mb={2}>
                          <div>
                            {Object.keys(selectedAssets).map((asset, index) => {
                              const { assetMode, assetTag, tag } = selectedAssets[asset];
                              return (
                                <div key={`${asset}-${index}`}>
                                  <Typography component="span" sx={classes.itemText}>{assetMode === "Inventory" ? "Inventory Tag" : "Asset Tag"}:</Typography>
                                  {tag || assetTag || ""}
                                </div>
                              );
                            })}
                          </div>
                        </Box>
                      </Box>
                      <Box width="25%" style={{ width: "25%" }}>
                        {/* Level */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>ECC Level:</Typography>
                          Level{" "}
                          <Typography component="span" sx={classes.textUpperCase}>
                            {eccLevel}
                          </Typography>
                        </Typography>
                        {/* Image Format */}
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>
                            Image Format:
                          </Typography>
                          <Typography component="span" sx={classes.textUpperCase}>
                            {format}
                          </Typography>
                        </Typography>
                        QR Version
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>QR Version:</Typography>
                          {version}
                        </Typography>
                      </Box>

                      {/* QR Size */}
                      <Box width="25%">
                        <Typography component="div" sx={classes.item}>
                          <Typography component="span" sx={classes.itemText}>QR Size:</Typography>
                          {magnification} pixel/cell
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* CQR Preview */}
      <Grid item xs={12}>
        <Box pt={5} pb={5} mt={3} mb={3} pl={2} pr={2} textAlign="center">
          <Card>
            <CardContent>
              <Typography component="h2" variant="h4">CQR Preview</Typography>
              {qrBackgroundSession.qrBackgroundSessionId.length > 0 ? (
                <img
                  alt="cqr"
                  src={qrBackgroundSession.image}
                  style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
                />
              ) : selectCQRLayout.value ? (
                <img
                  alt="cqr"
                  src={`${props.apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&qrBackground=${selectCQRLayout.label}`}
                  style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
                />
              ) : (
                <img
                  alt="cqr"
                  src={`${props.apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&showTag=true`}
                  style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
                />
              )}
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* Download CQRs */}
      <Grid item xs={12}>
        <Box pt={5} pb={5} pl={2} pr={2} textAlign="center">
          <Card>
            <CardContent>
              <Typography component="h2" variant="h4">Download CQRs</Typography>
              <p>
                You can download a ZIP file of images in the format you selected
                by clicking the Download button
              </p>
              <Box mt={2}>
                <MaterialUiButton
                  label="Download"
                  onClick={(value) => {
                    isLoading(true);
                    downloadCQR(value, {
                      ...props,
                      isLoading: isLoading,
                      onSuccess: props.onSuccess,
                    });
                  }}
                  size="medium"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {/* Go Back Button */}
      <Grid item xs={12}>
        <Box display="flex" mt={3} mb={3} textAlign="center">
          <button
            className="cqrback"
            onClick={() => {
              goBack();
            }}
            style={{ border: 0 }}
          >
            <ArrowCircleLeftIcon/>
          </button>
        </Box>
      </Grid>
    </Grid>
  );
}
