import { ReactComponent as CubeSolidIcon } from "../../../assets/faIcons/cube-solid.svg";
import { ReactComponent as CubesSolidIcon } from "../../../assets/faIcons/cubes-solid.svg";
import { ReactComponent as MapLocationDotSolidIcon } from "../../../assets/faIcons/map-location-dot-solid.svg";
import { ReactComponent as MicrochipSolidIcon } from "../../../assets/faIcons/microchip-solid.svg";
import { ReactComponent as SitemapSolidIcon } from "../../../assets/faIcons/sitemap-solid.svg";
import { withRouter } from "react-router-dom";
import { userSelectors } from "../../../globalStore/slices/user/userSlice";
import { useSelector } from "react-redux";
import Drawer from "./Drawer/FixedDrawer";
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import InventoryIcon from '@mui/icons-material/Inventory';
import NearMeIcon from '@mui/icons-material/NearMe';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

function SideNav(props) {
  const userRoles = useSelector(userSelectors.userActionsSelector);

  const linksMap = {};
  // This is where we determine what goes into the side nav. This is super clunky and NOT scalable.
  // Been trying to push for time to be smart about this, but until then we are forced to hard code this in.
  Object.keys(userRoles).forEach((action) => {
    switch (action) {
      case "View Asset History":
        linksMap.assetHistory = true;
        linksMap.assets = true;
        linksMap.assetStatus = true;
        break;
      case "View Batches":
        linksMap.batches = true;
        break;
      case "View Devices":
        linksMap.devices = true;
        break;
      case "View Facilities":
        linksMap.facilities = true;
        linksMap.locations = true;
        break;
      case "View Inventory":
        linksMap.inventory = true
        linksMap.inventoryStatus = true;
        linksMap.inventoryHistory = true
        break;
      case "View Organizations":
        linksMap.organizations = true;
        break;
      case "View Products":
        linksMap.products = true;
        break;
      case "View Settings":
        linksMap.settings = true;
        break;
      case "View Users":
        linksMap.users = true;
        break;
      default:
        linksMap.home = true;
        linksMap.support = true;
        linksMap.information = true;
        linksMap.automatedProcesses = true;
        break;
    }
  });

  const navLinks = [
    {
      name: "assets",
      label: "Assets",
      icon: <MapLocationDotSolidIcon/>,
    },
    { name: "products", label: "Products", icon: <CubeSolidIcon/> },
    { name: "batches", label: "Batches", icon: <CubesSolidIcon/> },
    { name: "facilities", label: "Facilities", icon: <NearMeIcon />},
    { name: "devices", label: "Trackers", icon: <MicrochipSolidIcon/> },
    { name: "inventory", label: "Inventory", icon: <InventoryIcon/> },
    { name: "organizations", label: "Organizations", icon: <SitemapSolidIcon/> },
    { name: "users", label: "Users", icon: <PersonIcon/> },
    // { name: "automatedProcesses", label: "Automated Processes", icon: <GearsSolidIcon/> },
    { name: "settings", label: "Settings", icon: <SettingsIcon/> },
  ].filter((link) => linksMap[link.name]);

  const stickyNavLinks = []

  return (
    <Drawer navLinks={navLinks} stickyNavLinks={stickyNavLinks} {...props} />
  );
}
export default withRouter(SideNav);
