import { useState } from "react";
import CustomDataField from "./CustomDataField";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../Forms/FieldTypes/TextField";

export default function CustomDataFields(props) {

  const classes = {
    pLeft: { paddingLeft: "8px" },
    pRight: { paddingRight: "8px" },
    root: { marginTop: "1rem" },
  }

  const [state, setState] = useState({ newFieldTitle: "", newFieldData: "" });

  function handleChange(value, count) {
    const { customDataFields, updateCustomFields } = props;
    customDataFields[count].value = value;
    updateCustomFields(customDataFields);
  }

  function removeCustomDataField(count) {
    const { customDataFields, updateCustomFields, onRemove } = props;
    const customFields = [...customDataFields];

    // This is being added incase the parent component needs another action to occur upon data field
    // deletion. This is being added due to products and customDataFields. The label builder is expecting
    // values from the propertiesMap object. We store other things in the propertiesMap that does not
    // qualify as a customDataField.
    if (onRemove !== undefined) {
      onRemove(customFields[count]);
    }

    customFields.splice(count, 1);
    updateCustomFields(customFields);
  }

  const {
    addField,
    setAddProductLevelDataElement,
    readOnly = false,
    customDataFields = [],
    updateCustomFields,
  } = props;
  const { newFieldTitle, newFieldData } = state;
  return (
    <Grid item xs={12}>
      <Grid container>
        {/* This renders any customDataFields provided from the props */}
        {customDataFields.map((element, index) => {
          return (
            <CustomDataField
              count={index}
              handleChange={handleChange}
              key={index}
              readOnly={readOnly}
              removeField={removeCustomDataField}
              title={customDataFields[index].title}
              value={customDataFields[index].value}
            />
          );
        })}
        {/* The code below appears if the user clicks the + to add a new field. 
          Promps for the title and content of a new data field. Once the check is 
          clicked the customDataFields of the parent component is updated.*/}
        {addField ? (
          <>
            {/* Product Level Data Label */}
            <Grid className={{...classes.pRight, ...classes.root}} item xs={6}>
              <SimpleTextField
                id="newFieldTitle"
                inputProps={{ "data-cypress-id": "products-product-mdl-txt-product-ldl" }}
                label="Product Level Data Label"
                onChange={(event) => {
                  setState((prevState) => ({
                    ...prevState,
                    newFieldTitle: event.target.value,
                  }));
                }}
                readOnly={readOnly}
                required
                value={newFieldTitle}
              />
            </Grid>

            {/* Product Level Data */}
            <Grid className={{...classes.pLeft, ...classes.root}} item xs={6}>
              <SimpleTextField
                id="newFieldData"
                inputProps={{ "data-cypress-id": "products-product-mdl-txt-product-ld" }}
                label="Product Level Data"
                onChange={(event) => {
                  setState((prevState) => ({
                    ...prevState,
                    newFieldData: event.target.value,
                  }));
                }}
                readOnly={readOnly}
                value={newFieldData}
              />
            </Grid>

            {/* Are you sure you want to add a custom data field? */}
            <Grid className={{...classes.pRight, ...classes.root}} item xs={6}>
              <MaterialUiButton
                color="secondary"
                cypressId="products-product-mdl-btn-save-level-data"
                label="SAVE"
                fullWidth={true}
                onClick={() => {
                  const customFields = customDataFields
                    ? [...customDataFields]
                    : [];
                  customFields.push({
                    title: newFieldTitle,
                    value: newFieldData,
                  });
                  updateCustomFields(customFields);
                  setState({
                    newFieldTitle: "",
                    newFieldData: "",
                  });
                  setAddProductLevelDataElement(false);
                }}
                variant="contained"
              />
            </Grid>
            <Grid className={{...classes.pLeft, ...classes.root}} item xs={6}>
              {/* The cancel clears out all fields and sets addField back to
              false */}
              <MaterialUiButton
                color="cancel"
                cypressId="products-product-mdl-btn-cancel-level-data"
                fullWidth={true}
                label="CANCEL"
                onClick={() => {
                  setState({
                    newFieldData: "",
                    newFieldTitle: "",
                  });
                  setAddProductLevelDataElement(false);
                }}
                variant="contained"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
